import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class RiderService {

    constructor(
        private http: HttpClient) { }

    verificarRiders(form: any) {
        return this.http.post(`${environment.api}/rider/verificar-riders`, form);
    }
}