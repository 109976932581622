import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public formLogin: FormGroup;
    public loading = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private adminService: AdminService
    ) { }

    ngOnInit(): void {
        if (localStorage.getItem('dataUser') !== null) {
            this.router.navigate(['/configuracion-cronometraje']);
        }

        this.initForm();
    }

    initForm() {
        this.formLogin = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    iniciarOffline() {
        localStorage.setItem('inicioOffline', true.toString());
        this.router.navigate(['/configuracion-cronometraje']);
    }

    login() {
        if (!this.formLogin.valid) {

            Swal.fire(
                'Uppps....!',
                'Te faltan datos que agregar',
                'error'
            );
            return;
        }

        this.loading = true;
        this.adminService.login(this.formLogin.value).subscribe(
            res => {
                localStorage.setItem('inicioOffline', false.toString());
                localStorage.setItem('dataUser', JSON.stringify({ email:res.email, token: res.token }));
                this.router.navigate(['/configuracion-cronometraje']);

            },
            err => {
                console.log(err);
                Swal.fire(
                    'Uppps....!',
                    'Correo electrónico/contraseña inválida.',
                    'error'
                );
                this.loading = false;

            }
        );
    }


}
