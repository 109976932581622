import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, throwError } from 'rxjs';

@Injectable()
export class ResultadoService {

    constructor(
        private http: HttpClient) { }

    importar(form: any) {
        return this.http.post(`${environment.api}/resultado/import-cronometraje-swticket`, form).pipe(
            catchError(err => {
              return throwError(() => new Error(err.message));
            })
          );
    }

    importarExterno(form: any) {
        return this.http.post(`${environment.api}/resultado/import-cronometraje-externo-swticket`, form).pipe(
            catchError(err => {
              return throwError(() => new Error(err.message));
            })
          );
    }
}