import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-left',
  templateUrl: './navbar-left.component.html',
  styleUrls: ['./navbar-left.component.scss']
})
export class NavbarLeftComponent implements OnInit {
  public inicioOffline: string = 'true';

  constructor() { }

  ngOnInit(): void {
    const isOffline = localStorage.getItem('inicioOffline');
    if (isOffline !== 'true') {
        this.inicioOffline = 'false';
    }
  }

}
