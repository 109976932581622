import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ResultadoRiderService {

    constructor(
        private http: HttpClient) { }

    importar(form: any) {
        return this.http.post(`${environment.api}/resultado-rider/import-cronometraje-swticket`, form);
    }

    importarExterno(form: any) {
        return this.http.post(`${environment.api}/resultado-rider/import-cronometraje-externo-swticket`, form);
    }
}