<div class="services-details-item">
    <div class="services-details-more">
        <br>
        <h3>ADMINISTRACIÓN CRONOMETRAJE</h3>

        <ul>
            <li><a routerLink="/configuracion-cronometraje">Configuraciones generales <i class='bx bxs-cog'></i></a>
            </li>
            <li><a routerLink="/entrega-dorsales">Entrega Dorsales <i class='bx bxs-memory-card'></i></a></li>
            <li><a routerLink="/marcaje-administracion">Marcaje administración <i class='bx bxs-timer'></i></a></li>

        </ul>
    </div>
</div>