import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { EventoRiderService } from 'src/app/services/evento-rider.service';
import { EventoService } from 'src/app/services/evento.service';
import { EPlataforma } from 'src/app/shared/const/plataforma.enum';
import { ETipoConexion } from 'src/app/shared/const/tipo-conexion.enum';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-configuracion-cronometraje',
    templateUrl: './configuracion-cronometraje.component.html',
    styleUrls: ['./configuracion-cronometraje.component.scss']
})
export class ConfiguracionCronometrajeComponent implements OnInit {

    public loading = false;
    public path = null;
    public nombreEvento = null;
    public tipoClasificacion = null;
    public tipoDisciplina = null;
    public dividirPantalla = null;
    public cantidadPorTabla = null;
    public ordenPantalla = null;
    public cantidadMarcajeAdminitracionCat = null;
    public cantidadMarcajeAdminitracionDes = null;
    public cantidadMarcajeAdminitracionGiro = null;
    public cambiarCat = null;
    public desafios = [];
    public desafiosPremiacion = [];
    public marcajeAdmMostrarEnRuta = null;
    public formPath: FormGroup;
    public inicioOffline: string = 'true';
    public tipoConexion = null;
    public pestanaSeleccionada = 0;
    public eventos = [];
    public plataforma = null;
    public eventoId = null;

    constructor(private router: Router,
        private fb: FormBuilder,
        private localStorageAs: LocalStorageService,
        private adminService: AdminService,
        private eventoService: EventoService,
        private eventoRiderService: EventoRiderService

    ) { }

    ngOnInit(): void {
        this.path = localStorage.getItem('path');
        this.nombreEvento = localStorage.getItem('nombre_evento');
        this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        this.dividirPantalla = localStorage.getItem('dividir_pantalla');
        this.cantidadPorTabla = localStorage.getItem('cantidad_tabla');
        this.cantidadMarcajeAdminitracionCat = localStorage.getItem('cantidad_marcaje_administracion_cat');
        this.cantidadMarcajeAdminitracionDes = localStorage.getItem('cantidad_marcaje_administracion_des');
        this.cantidadMarcajeAdminitracionGiro = localStorage.getItem('cantidad_marcaje_administracion_giro');
        this.ordenPantalla = localStorage.getItem('orden_pantalla');
        this.cambiarCat = localStorage.getItem('cambiar_cat');
        this.marcajeAdmMostrarEnRuta = localStorage.getItem('marcaje_adm_mostrar_en_ruta');
        this.tipoConexion = localStorage.getItem('tipoConexion');
        this.plataforma = localStorage.getItem('plataforma');
        this.eventoId = localStorage.getItem('eventoId');

        this.localStorageAs.watch('desafios').subscribe(desafiosStorage => {
            if (desafiosStorage !== null) {
                this.desafios = JSON.parse(desafiosStorage);

            }
        });

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null) {
                this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);

            }
        });


        const isOffline = localStorage.getItem('inicioOffline');
        if (isOffline !== 'true') {
            this.inicioOffline = 'false';
        }

        this.initForm();
        this.agregarConfiguracionPremiacion();

    }

    initForm() {
        this.formPath = this.fb.group({
            url: [this.path ? this.path : '', Validators.required],
            nombreEvento: [this.nombreEvento ? this.nombreEvento : '', Validators.required],
            tipoClasificacion: [this.tipoClasificacion ? this.tipoClasificacion : '', Validators.required],
            tipoDisciplina: [this.tipoDisciplina ? this.tipoDisciplina : '', Validators.required],
            dividirPantalla: [this.dividirPantalla ? this.dividirPantalla : '', Validators.required],
            cantidadPorTabla: [this.cantidadPorTabla ? this.cantidadPorTabla : 0, Validators.required],
            cantidadMarcajeAdminitracionCat: [this.cantidadMarcajeAdminitracionCat ? this.cantidadMarcajeAdminitracionCat : 0, Validators.required],
            cantidadMarcajeAdminitracionDes: [this.cantidadMarcajeAdminitracionDes ? this.cantidadMarcajeAdminitracionDes : 0, Validators.required],
            cantidadMarcajeAdminitracionGiro: [this.cantidadMarcajeAdminitracionGiro ? this.cantidadMarcajeAdminitracionGiro : 0, Validators.required],
            ordenPantalla: [this.ordenPantalla ? this.ordenPantalla : '', Validators.required],
            tipoConexion: [this.ordenPantalla ? this.tipoConexion : '', Validators.required],
            cambiarCat: [this.cambiarCat ? this.cambiarCat : '', Validators.required],
            marcajeAdmMostrarEnRuta: [this.marcajeAdmMostrarEnRuta ? this.marcajeAdmMostrarEnRuta : '', Validators.required],
            configuracionPremiacion: this.fb.group({}),
            plataforma: [this.plataforma ? this.plataforma : '', this.inicioOffline === 'true' ? [] : Validators.required],
            eventoId: [this.eventoId ? this.eventoId : '', this.inicioOffline === 'true' ? [] : Validators.required],
        });
    }

    get items(): FormGroup {
        return this.formPath.get('configuracionPremiacion') as FormGroup;
    }

    agregarConfiguracionPremiacion() {
        const itemGroup = this.fb.group({});

        this.desafios.forEach((desafio) => {
            const cantidad = this.desafiosPremiacion[desafio];
            this.items.addControl(desafio, this.fb.control(cantidad === undefined ? 0 : cantidad, Validators.required));
        });

    }

    logout() {
        this.adminService.logout();
    }

    update() {
        console.log(this.formPath.value);
        if (!this.formPath.valid) {
            Swal.fire(
                'Uppps....!',
                'Faltan campos obligatorios',
                'error'
            );
            return;
        }

        localStorage.setItem('path', this.formPath.get('url').value);
        if (this.inicioOffline === 'true') {
            localStorage.setItem('nombre_evento', this.formPath.get('nombreEvento').value);
        }
        localStorage.setItem('tipo_clasificacion', this.formPath.get('tipoClasificacion').value);
        localStorage.setItem('tipo_disciplina', this.formPath.get('tipoDisciplina').value);
        localStorage.setItem('dividir_pantalla', this.formPath.get('dividirPantalla').value);
        localStorage.setItem('cantidad_tabla', this.formPath.get('cantidadPorTabla').value);
        localStorage.setItem('cantidad_marcaje_administracion_cat', this.formPath.get('cantidadMarcajeAdminitracionCat').value);
        localStorage.setItem('cantidad_marcaje_administracion_des', this.formPath.get('cantidadMarcajeAdminitracionDes').value);
        localStorage.setItem('cantidad_marcaje_administracion_giro', this.formPath.get('cantidadMarcajeAdminitracionGiro').value);
        localStorage.setItem('orden_pantalla', this.formPath.get('ordenPantalla').value);
        localStorage.setItem('cambiar_cat', this.formPath.get('cambiarCat').value);
        localStorage.setItem('marcaje_adm_mostrar_en_ruta', this.formPath.get('marcajeAdmMostrarEnRuta').value);
        localStorage.setItem('tipoConexion', this.formPath.get('tipoConexion').value);
        localStorage.setItem('plataforma', this.formPath.get('plataforma').value);
        localStorage.setItem('eventoId', this.formPath.get('eventoId').value);

        if (this.inicioOffline === 'false' && this.tipoConexion === ETipoConexion.BIKERACECLOUD && this.eventos.length > 0) {
            localStorage.setItem('nombre_evento', this.eventos.find((evento) => evento.id === Number(this.formPath.get('eventoId').value)).nombre);
        }

        this.localStorageAs.set('desafios_premiacion', JSON.stringify(this.formPath.get('configuracionPremiacion').value));

        Swal.fire(
            'Configuración actualizada!',
            'Se ha guardado la configuración correctamente.',
            'success'
        );

        this.router.navigate(['/marcaje']);
    }

    obtenerTipoDisciplina() {
        return this.formPath.get('tipoDisciplina').value;
    }

    cambioPestana(indice) {
        this.pestanaSeleccionada = indice;

        if (this.pestanaSeleccionada === 4) {
            this.cambioPlataforma();
        }
    }

    async cambioPlataforma() {
        switch (this.formPath.get('plataforma').value) {
            case EPlataforma.BIKERACE:
                this.eventos = await lastValueFrom(this.eventoService.obtenerEventosSimplify('POR_REALIZAR, INSCRIPCIONES_CERRADAS,INSCRIPCIONES_EXTERNAS'))
                break;
            case EPlataforma.RIDERTICKET:
                this.eventos = await lastValueFrom(this.eventoRiderService.obtenerEventosSimplify('POR_REALIZAR, INSCRIPCIONES_CERRADAS,INSCRIPCIONES_EXTERNAS'))
                break;
        }
    }

    seleccionarEvento(){
        this.nombreEvento = this.eventos.find((evento) => evento.id === Number(this.formPath.get('eventoId').value)).nombre;
        localStorage.setItem('nombre_evento', this.nombreEvento);
        localStorage.setItem('eventoId', this.formPath.get('eventoId').value);
        this.eventoId = Number(this.formPath.get('eventoId').value);
        this.formPath.get('nombreEvento').setValue(this.nombreEvento);
    }
}
