import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-marcaje',
    templateUrl: './marcaje.component.html',
    styleUrls: ['./marcaje.component.scss']
})
export class MarcajeComponent implements OnInit {
    public dividirPantalla = null;


    constructor(
    ) { }

    ngOnInit(): void {
        this.dividirPantalla = localStorage.getItem('dividir_pantalla');

    }


}
