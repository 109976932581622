<div class="services-details-item">
    <div class="services-details-more">
        <br>
        <h3>ADMINISTRACIÓN CRONOMETRAJE</h3>

        <ul>
            <li><a routerLink="/configuracion-cronometraje">Configuraciones generales <i class='bx bxs-cog'></i></a>
            </li>
            <li><a routerLink="/entrega-dorsales">Entrega Dorsales <i class='bx bxs-memory-card'></i></a></li>
            <li><a routerLink="/marcaje-administracion">Marcaje administración <i class='bx bxs-timer'></i></a></li>
            <li *ngIf="inicioOffline === 'false'"><a routerLink="/resultados">Resultados <i
                        class='bx bxs-timer'></i></a></li>
            <li *ngIf="inicioOffline === 'false'"><a routerLink="/envio-resultados">Envío resultados <i
                        class='bx bx-stats'></i></a></li>

            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
        </ul>
    </div>
</div>