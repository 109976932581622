<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <app-navbar-left></app-navbar-left>
            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav class="nav nav-pills nav-justified" style="margin-top: 15px;">
                                    <a class="nav-link pointer" (click)="cambioPestana(0)"
                                        [ngClass]="pestanaSeleccionada === 0 ? 'active' : ''">Conexión</a>
                                    <a class="nav-link pointer" (click)="cambioPestana(1)"
                                        [ngClass]="pestanaSeleccionada === 1 ? 'active' : ''">Evento</a>
                                    <a class="nav-link pointer" (click)="cambioPestana(2)"
                                        [ngClass]="pestanaSeleccionada === 2 ? 'active' : ''">Vista pantalla</a>
                                    <a class="nav-link pointer" (click)="cambioPestana(3)"
                                        [ngClass]="pestanaSeleccionada === 3 ? 'active' : ''">Marcaje</a>
                                    <a *ngIf="inicioOffline ==='false'" class="nav-link pointer" (click)="cambioPestana(4)"
                                        [ngClass]="pestanaSeleccionada === 4 ? 'active' : ''">Resultados</a>
                                </nav>

                                <div class="checkout-item ">

                                    <div class="checkout-one" style="max-width: none; padding: 15px;">
                                        <form [formGroup]="formPath">

                                            <ng-container *ngIf="pestanaSeleccionada === 0">
                                                <h3 style="text-align: center;">Configuración conexión</h3>
                                                <br>
                                                <div class="form-group">
                                                    <label>Tipo conexión: <span style="color:red">*</span></label>
                                                    <select formControlName="tipoConexion" class="form-control">
                                                        <option value="BIKERACECLOUD" *ngIf="inicioOffline ==='false'">Bikerace Cloud</option>
                                                        <option value="RJ45">RJ45</option>
                                                    </select>

                                                </div>
                                                <div class="form-group">
                                                    <label>URL: <span style="color:red">*</span></label>
                                                    <input formControlName="url" type="text" class="form-control">

                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pestanaSeleccionada === 1">
                                                <h3 style="text-align: center;">Configuración evento</h3>
                                                <br>
                                                <div class="form-group">

                                                    <label>Nombre evento: <span style="color:red">*</span></label>
                                                    <input formControlName="nombreEvento" type="text"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">

                                                    <label>Tipo de clasificación: <span
                                                            style="color:red">*</span></label>
                                                    <select formControlName="tipoClasificacion" class="form-control">
                                                        <option value="Por Giro">Por Giro</option>
                                                        <option value="Por Desafio">Por Desafio</option>
                                                    </select>
                                                </div>

                                                <div class="form-group">

                                                    <label>Tipo de disciplina: <span style="color:red">*</span></label>
                                                    <select formControlName="tipoDisciplina" class="form-control">
                                                        <option value="Trail">Trail</option>
                                                        <option value="Ciclismo">Ciclismo</option>
                                                        <option value="non-stop">Non Stop</option>
                                                        <option value="Motociclismo">Motociclismo</option>

                                                    </select>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pestanaSeleccionada === 2">
                                                <h3 style="text-align: center;">Configuración vista Pantalla</h3>
                                                <br>
                                                <div class="form-group">

                                                    <label>Dividir pantalla principal x: <span
                                                            style="color:red">*</span></label>
                                                    <select formControlName="dividirPantalla" class="form-control">
                                                        <option value="Desafio">Desafío</option>
                                                        <option value="Giro">Giro</option>
                                                        <option value="Categoria">Categoría</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">

                                                    <label>Cantidad deportistas por tabla: <span
                                                            style="color:red">*</span></label>
                                                    <input formControlName="cantidadPorTabla" type="number"
                                                        class="form-control">
                                                </div>
                                                <div class="form-group">

                                                    <label>Orden deportistas: <span style="color:red">*</span></label>
                                                    <select formControlName="ordenPantalla" class="form-control">
                                                        <option value="ASC">Del 1º al último</option>
                                                        <option value="DESC">Del último al 1º</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">

                                                    <label>Cambiar categorías despues de 10 seg.: <span
                                                            style="color:red">*</span></label>
                                                    <select formControlName="cambiarCat" class="form-control">
                                                        <option value="ACTIVADO">Activado</option>
                                                        <option value="DESACTIVADO">Desactivado</option>
                                                    </select>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pestanaSeleccionada === 3">
                                                <h3 style="text-align: center;">Configuración marcaje administración
                                                </h3>
                                                <br>
                                                <div class="form-group">

                                                    <label>Cantidad deportistas a imprimir por categoría: <span
                                                            style="color:red">*</span></label>
                                                    <input formControlName="cantidadMarcajeAdminitracionCat"
                                                        type="number" class="form-control">
                                                </div>
                                                <div class="form-group">

                                                    <label>Cantidad deportistas a imprimir por desafío: <span
                                                            style="color:red">*</span></label>
                                                    <input formControlName="cantidadMarcajeAdminitracionDes"
                                                        type="number" class="form-control">
                                                </div>
                                                <div class="form-group">

                                                    <label>Cantidad deportistas a imprimir por giro: <span
                                                            style="color:red">*</span></label>
                                                    <input formControlName="cantidadMarcajeAdminitracionGiro"
                                                        type="number" class="form-control">
                                                </div>
                                                <div class="form-group">

                                                    <label>Cantidad deportistas en ruta: <span
                                                            style="color:red">*</span></label>
                                                    <input formControlName="marcajeAdmMostrarEnRuta" type="number"
                                                        class="form-control">
                                                </div>
                                                <hr *ngIf="obtenerTipoDisciplina() === 'Trail'">
                                                <h3 *ngIf="obtenerTipoDisciplina() === 'Trail'"
                                                    style="text-align: center;">
                                                    Configuración premiación</h3>
                                                <p *ngIf="obtenerTipoDisciplina() === 'Trail'"
                                                    style="text-align: center;">
                                                    Correr posiciones por desafio</p>
                                                <br *ngIf="obtenerTipoDisciplina() === 'Trail'">
                                                <ng-container *ngIf="obtenerTipoDisciplina() === 'Trail'"
                                                    formGroupName="configuracionPremiacion">
                                                    <div class="form-group"
                                                        *ngFor="let controlName of items?.controls | keyvalue">

                                                        <label [for]="controlName.key">{{ controlName.key }}: <span
                                                                style="color:red">*</span></label>
                                                        <input [id]="controlName.key"
                                                            [formControlName]="controlName.key" type="number"
                                                            class="form-control">
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="pestanaSeleccionada === 4 && inicioOffline ==='false'">
                                                <h3 style="text-align: center;">Seleccion evento a cronometrar</h3>
                                                <br>
                                                <div class="form-group">
                                                    <label>Plataforma: <span style="color:red">*</span></label>
                                                    <select formControlName="plataforma" class="form-control" (change)="cambioPlataforma()">
                                                        <option value="BIKERACE">Bikerace</option>
                                                        <option value="RIDERTICKET">Riderticket</option>
                                                    </select>

                                                </div>

                                                <div class="form-group">
                                                    <label>Evento: <span style="color:red">*</span></label>
                                                    <select formControlName="eventoId" class="form-control" (change)="seleccionarEvento()">
                                                        <option *ngFor="let evento of eventos" [value]="evento.id">{{evento.nombre}}</option>
                                                    </select>

                                                </div>
                                            </ng-container>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="text-center">
                            <button (click)="update()" class="btn cmn-btn">Actualizar información</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-preloader *ngIf="loading"></app-preloader>