import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './components/pages/error/error.component';
import {LoginComponent} from './components/pages/login/login.component';
import {AuthUserGuard} from './guards/auth-user.guard';
import {MarcajeComponent} from './components/pages/marcaje/marcaje.component';
import {
    ConfiguracionCronometrajeComponent
} from './components/pages/configuracion-cronometraje/configuracion-cronometraje.component';
import {BuscarInformacionComponent} from './components/pages/buscar-informacion/buscar-informacion.component';
import {EntregarComprobanteComponent} from './components/pages/entregar-comprobante/entregar-comprobante.component';
import {
    MarcajeAdminitracionComponent
} from './components/pages/marcaje-administracion/marcaje-administracion.component';
import { ResultadosOnlineComponent } from './components/pages/resultados-online/resultados-online.component';
import { EnvioResultadosComponent } from './components/pages/envio-resultados/envio-resultados.component';
import { ResultadosComponent } from './components/pages/resultados/resultados.component';


const routes: Routes = [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'configuracion-cronometraje', component: ConfiguracionCronometrajeComponent, canActivate: [AuthUserGuard]},
    {path: 'buscar-informacion', component: BuscarInformacionComponent, canActivate: [AuthUserGuard]},
    {path: 'entrega-dorsales', component: EntregarComprobanteComponent, canActivate: [AuthUserGuard]},
    {path: 'marcaje-administracion', component: MarcajeAdminitracionComponent, canActivate: [AuthUserGuard]},
    {path: 'marcaje', component: MarcajeComponent, canActivate: [AuthUserGuard]},
    {path: 'envio-resultados', component: EnvioResultadosComponent, canActivate: [AuthUserGuard]},
    {path: 'resultados', component: ResultadosComponent, canActivate: [AuthUserGuard]},
    {path: 'error', component: ErrorComponent},
    {path: 'resultados-online', component: ResultadosOnlineComponent},
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy' , onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
