
<section class="collection-area collection-area-two" style="width: 100%; height: calc(100% - 109px); display: inline-block; margin-top: 109px;">
    <div style="width: 90%; height: 100%; float: left;"  *ngIf="registros.length>0">
        <!-- <div class="row" style="margin-bottom: 20px">
            <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                    [style.width]="porcentaje + '%'">
                    {{porcentaje}}%
                </div>
            </div>
        </div> -->
        <div *ngIf="primerasOcurrencias().length > 0" style="width: 50%;height:50%;float: left; overflow-y: auto; padding: 10px">
            <h2 style="text-align: center; background-color: #ffbe01; color: black;font-weight: bold;font-size: 1.5vh;" >{{formatearNombre(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[0] : 0].desafio, false)}}</h2>
                <table class="default">
                    <tr style="color:black; font-weight: bold;  font-size: 1vh;  background-color: #ffbe01;">
                        <th>DOR</th>
                        <th>Nombre</th>
                        <th>Sexo</th>
                        <th>Pos. Gral</th>
                        <th>Pos. Gral x  sexo</th>
                        <th>Pos. Cat</th>
                        <th>Giros</th>
                        <th>Tiempo</th>
    
                    </tr>
                    <ng-container *ngFor="let marcaje of arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[0] : 0].desafio, true)">
                        <tr [ngClass]="{'red': !marcajeEnRuta(marcaje.estadoTiempo), 'green' : marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados === marcaje.giros, 'yellow' :  marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados !== marcaje.giros}">
                            <td style="text-align: center;font-size: 1vh;">{{marcaje.dor}}</td>
                            <td  style="text-align: center;font-size: 1vh">{{marcaje.nombre}} {{marcaje.apellido}}</td>
                            <td  style="text-align: center;font-size: 1vh;">{{marcaje.sexo}}</td>
                            <td style="text-align: center;font-size: 1vh;">
                                <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio}}</span>
                                <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro}}</span>
                            </td>
                            <td style="text-align: center;font-size: 1vh;">
                                <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio_sexo}}</span>
                                <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro_sexo}}</span>
                            </td>
                            <td style="text-align: center;font-size: 1vh;">{{marcaje.posicion_categoria}}</td>
                            <td style="text-align: center;font-size: 1vh;">{{marcaje.girosCompletados}} / {{marcaje.giros}}</td>
    
                            <td style="text-align: center;font-size: 1vh;">{{marcaje.tiempo}}</td>
        
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[0] : 0].desafio, true).length < cantidadPorTabla">
                        <tr *ngFor="let _ of iteraciones(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[0] : 0].desafio); let i = index">
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                            <td style="text-align: center; font-size: 1vh;">....</td>
                        </tr>
                    </ng-container>
                    <tr style="color:white;    background-color: #ffbe01;">
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>

                    </tr>
                   
    
                </table>
            
        </div>
        <div *ngIf="primerasOcurrencias().length > 1" style="width: 50%;height: 50%;float: right;overflow-y: auto;padding: 10px">
            <h2 style="text-align: center; background-color: #ffbe01; color: black;font-weight: bold;font-size: 1.5vh;" >{{formatearNombre(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[1] : 1].desafio, false)}}</h2>
            <table class="default">
                <tr style="color:black; font-weight: bold;  font-size: 1vh;  background-color: #ffbe01;">
                    <th>DOR</th>
                    <th>Nombre</th>
                    <th>Sexo</th>
                    <th>Pos. Gral</th>
                    <th>Pos. Gral x  sexo</th>
                    <th>Pos. Cat</th>
                    <th>Giros</th>
                    <th>Tiempo</th>

                </tr>
                <ng-container *ngFor="let marcaje of arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[1] : 1].desafio, true)">
                    <tr [ngClass]="{'red': !marcajeEnRuta(marcaje.estadoTiempo), 'green' : marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados === marcaje.giros, 'yellow' :  marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados !== marcaje.giros}">
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.dor}}</td>
                        <td  style="text-align: center;font-size: 1vh">{{marcaje.nombre}} {{marcaje.apellido}}</td>
                        <td  style="text-align: center;font-size: 1vh;">{{marcaje.sexo}}</td>
                        <td style="text-align: center;font-size: 1vh;">
                            <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio}}</span>
                            <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro}}</span>
                        </td>
                        <td style="text-align: center;font-size: 1vh;">
                            <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio_sexo}}</span>
                            <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro_sexo}}</span>
                        </td>
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.posicion_categoria}}</td>
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.girosCompletados}} / {{marcaje.giros}}</td>

                        <td style="text-align: center;font-size: 1vh;">{{marcaje.tiempo}}</td>
    
                    </tr>
                </ng-container>
                <ng-container *ngIf="arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[1] : 1].desafio, true).length < cantidadPorTabla">
                    <tr *ngFor="let _ of iteraciones(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[1] : 1].desafio); let i = index">
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                    </tr>
                </ng-container>
                <tr style="color:white;    background-color: #ffbe01;">
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>

                </tr>
               

            </table>
        </div>
        <div *ngIf="primerasOcurrencias().length > 2" style="width: 50%;height: 50%;float: left;overflow-y: auto;padding: 10px">
            <h2 style="text-align: center; background-color: #ffbe01; color: black;font-weight: bold;font-size: 1.5vh;" >{{formatearNombre(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[2] : 2].desafio, false)}}</h2>
            <table class="default">
                <tr style="color:black; font-weight: bold;  font-size: 1vh;  background-color: #ffbe01;">
                    <th>DOR</th>
                    <th>Nombre</th>
                    <th>Sexo</th>
                    <th>Pos. Gral</th>
                    <th>Pos. Gral x  sexo</th>
                    <th>Pos. Cat</th>
                    <th>Giros</th>
                    <th>Tiempo</th>

                </tr>
                <ng-container *ngFor="let marcaje of arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[2] : 2].desafio, true)">
                    <tr [ngClass]="{'red': !marcajeEnRuta(marcaje.estadoTiempo), 'green' : marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados === marcaje.giros, 'yellow' :  marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados !== marcaje.giros}">
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.dor}}</td>
                        <td  style="text-align: center;font-size: 1vh">{{marcaje.nombre}} {{marcaje.apellido}}</td>
                        <td  style="text-align: center;font-size: 1vh;">{{marcaje.sexo}}</td>
                        <td style="text-align: center;font-size: 1vh;">
                            <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio}}</span>
                            <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro}}</span>
                        </td>
                        <td style="text-align: center;font-size: 1vh;">
                            <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio_sexo}}</span>
                            <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro_sexo}}</span>
                        </td>
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.posicion_categoria}}</td>
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.girosCompletados}} / {{marcaje.giros}}</td>

                        <td style="text-align: center;font-size: 1vh;">{{marcaje.tiempo}}</td>
    
                    </tr>
                </ng-container>
                <ng-container *ngIf="arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[2] : 2].desafio, true).length < cantidadPorTabla">
                    <tr *ngFor="let _ of iteraciones(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[2] : 2].desafio); let i = index">
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                    </tr>
                </ng-container>
                <tr style="color:white;    background-color: #ffbe01;">
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>

                </tr>
               

            </table>
        </div>
        <div *ngIf="primerasOcurrencias().length > 3" style="width: 50%;height: 50%; float: right;overflow-y: auto;padding: 10px">
            <h2 style="text-align: center; background-color: #ffbe01; color: black;font-weight: bold;font-size: 1.5vh;" >{{formatearNombre(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[3] : 3].desafio, false)}}</h2>
            <table class="default">
                <tr style="color:black; font-weight: bold;  font-size: 1vh;  background-color: #ffbe01;">
                    <th>DOR</th>
                    <th>Nombre</th>
                    <th>Sexo</th>
                    <th>Pos. Gral</th>
                    <th>Pos. Gral x  sexo</th>
                    <th>Pos. Cat</th>
                    <th>Giros</th>
                    <th>Tiempo</th>

                </tr>
                <ng-container *ngFor="let marcaje of arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[3] : 3].desafio, true)">
                    <tr [ngClass]="{'red': !marcajeEnRuta(marcaje.estadoTiempo), 'green' : marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados === marcaje.giros, 'yellow' :  marcajeEnRuta(marcaje.estadoTiempo) && marcaje.girosCompletados !== marcaje.giros}">
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.dor}}</td>
                        <td  style="text-align: center;font-size: 1vh">{{marcaje.nombre}} {{marcaje.apellido}}</td>
                        <td  style="text-align: center;font-size: 1vh;">{{marcaje.sexo}}</td>
                        <td style="text-align: center;font-size: 1vh;">
                            <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio}}</span>
                            <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro}}</span>
                        </td>
                        <td style="text-align: center;font-size: 1vh;">
                            <span *ngIf="tipoClasificacion==='Por Desafio'">{{marcaje.posicion_desafio_sexo}}</span>
                            <span *ngIf="tipoClasificacion==='Por Giro'">{{marcaje.posicion_giro_sexo}}</span>
                        </td>
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.posicion_categoria}}</td>
                        <td style="text-align: center;font-size: 1vh;">{{marcaje.girosCompletados}} / {{marcaje.giros}}</td>

                        <td style="text-align: center;font-size: 1vh;">{{marcaje.tiempo}}</td>
    
                    </tr>
                </ng-container>
                <ng-container *ngIf="arrayCantidadDesafios(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[3] : 3].desafio, true).length < cantidadPorTabla">
                    <tr *ngFor="let _ of iteraciones(primerasOcurrencias()[cambiarCat === 'ACTIVADO' ?  this.numerosRandom[3] : 3].desafio); let i = index">
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                        <td style="text-align: center; font-size: 1vh;">....</td>
                    </tr>
                </ng-container>
                <tr style="color:white;    background-color: #ffbe01;">
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>
                    <td style="text-align: center; font-size: 1vh;">....</td>

                </tr>
               

            </table>
        </div>
    </div>

    <div style="width: 10%; height: 100%; float: right;">
        <ul>
            <ng-container *ngFor="let elemento of split(registros, 10)">
                <li [ngClass]="{'red': !marcajeEnRuta(buscarDor(elemento.tag).estadoTiempo), 'green' : marcajeEnRuta(buscarDor(elemento.tag ).estadoTiempo) && buscarDor(elemento.tag ).girosCompletados === buscarDor(elemento.tag ).giros, 'yellow' :  marcajeEnRuta(buscarDor(elemento.tag ).estadoTiempo) && buscarDor(elemento.tag ).girosCompletados !== buscarDor(elemento.tag ).giros}" >
                    <span>
                        {{ buscarDor(elemento.tag ).dor}}
                    </span>
                    <span style="font-size: 1vh;">
                        {{buscarDor(elemento.tag ).tiempo}}
                    </span>
                    <span style="font-size: 1vh;">
                        {{buscarDor(elemento.tag).nombre}} {{buscarDor(elemento.tag).apellido}} 
                    </span>
                    <span style="font-size: 1vh;">
                        {{buscarDor(elemento.tag).girosCompletados}} / {{buscarDor(elemento.tag).giros}} 
                    </span>
                </li>

            </ng-container>
          </ul>
    </div>
</section>

