import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ReadFileService {

    constructor(private http: HttpClient) { }

    getFile(path: string) {
        return this.http.get(`${path}`, {
            responseType: 'text',
        })
    }



    getDirectory(path: string) {
        return this.http.get(`${path}`, { responseType: 'text' });
    }



}
