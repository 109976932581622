import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncryptService } from '../shared/encrypt';

@Injectable()
export class Encryptnterceptor implements HttpInterceptor {

  constructor(
    private encryptService: EncryptService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const ruta = localStorage.getItem('path');
    if (!req.url.includes(ruta)
    ) {
      req = req.clone({
        responseType: 'text',
        headers: req.headers.set('Accept', 'text/plain')
      });
      if (req.method === 'POST' || req.method === 'PATCH') {
        req = req.clone({
          body: this.encryptService.encryptData(req.body),
        });
      }
    }




    return next.handle(req).pipe(

      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if(event.url.match(/\b\w*:8080\w*\b/g)=== null && event.url.match(/\b\w*lOG_resultados\w*\b/g) === null && event.url.match(/\b\w*lOG_registros\w*\b/g) === null){
            // Obtener la respuesta del servidor
            const response = event.body;
                  
            // Modificar la respuesta según sea necesario
            const modifiedResponse = this.encryptService.decryptData(response);

            // Crear una nueva respuesta con los datos modificados
            const modifiedEvent = event.clone({ body: modifiedResponse });

            // Devolver la respuesta modificada
            return modifiedEvent;
          }else{
            return event;
          }
        }
      })
    );
  }
}
