import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, lastValueFrom, Subscription } from 'rxjs';
import { CorredorService } from 'src/app/services/corredor.service';
import { ReadFileService } from 'src/app/services/read-file.service';
import { ResultadoRiderService } from 'src/app/services/resultado-rider.service';
import { ResultadoService } from 'src/app/services/resultado.service';
import { RiderService } from 'src/app/services/rider.service';
import { SocketService } from 'src/app/services/socket.service';
import { estadoTiempo } from 'src/app/shared/const/estado-tiempo.enum';
import { EPlataforma } from 'src/app/shared/const/plataforma.enum';
import { sexo } from 'src/app/shared/const/sexo.enum';
import { ETipoConexion } from 'src/app/shared/const/tipo-conexion.enum';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-resultados',
    templateUrl: './resultados.component.html',
    styleUrls: ['./resultados.component.scss']
})
export class ResultadosComponent implements OnInit {
    public loading = false;
    public marcajes: any[] = [];

    public pestanaSeleccionada = 0;
    public eventoId = 0;
    public tipoConexion = '';
    public inicioOffline = '';
    public plataforma = '';
    public tipoClasificacion = null;
    public tipoDisciplina = null;
    public cantidadMarcajeAdminitracionCat = null;
    public cantidadMarcajeAdminitracionDes = null;
    public cantidadMarcajeAdminitracionGiro = null;
    public desafiosPremiacion = [];
    private resultadosSubscription: Subscription;
    public registrosSocket = {};
    public subscription: Subscription;
    public cantidadDeportistasEnRuta = 0;
    public porcentaje = 0;
    public prefixPathResultados = '';
    public fin = false;
    public lugarCategoriasGeneral: any = {};
    public lugarGirosGeneral: any = {};
    public lugarDesafioGeneral: any = {};
    public lugarCategorias: any = {};
    paginatedData: any[] = [];
    pageSize: number = 30;
    currentPage: number = 0;
    pages: number[] = [];

    constructor(
        private _router: Router,
        private localStorageAs: LocalStorageService,
        private socketService: SocketService,
        private readFileService: ReadFileService,
        private riderService: RiderService,
        private corredorService: CorredorService,
        private resultadoService: ResultadoService,
        private resultadoRiderService: ResultadoRiderService,

    ) { }

    ngOnInit(): void {
        if (localStorage.getItem('eventoId')) {
            this.eventoId = Number(localStorage.getItem('eventoId'));
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta seleccionar el evento en la pestaña resultados',
                'error'
            );
            this._router.navigate(['/configuracion-cronometraje']);
            return;

        }

        if (localStorage.getItem('tipoConexion')) {
            this.tipoConexion = localStorage.getItem('tipoConexion');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de conexión',
                'error'
            );
            this._router.navigate(['/configuracion-cronometraje']);
            return;
        }

        this.inicioOffline = localStorage.getItem('inicioOffline');

        if (this.tipoConexion === ETipoConexion.BIKERACECLOUD && this.inicioOffline === 'true') {
            Swal.fire(
                'Uppps....!',
                'Seleccionaste tipo de conexión Bikerace Cloud y iniciaste de forma OFFLINE',
                'error');
            this._router.navigate(['/configuracion-cronometraje']);
            return;
        }

        if (localStorage.getItem('path')) {
            this.getMarcajes();
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }

        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_marcaje_administracion_cat')) {
            this.cantidadMarcajeAdminitracionCat = localStorage.getItem('cantidad_marcaje_administracion_cat');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de marcaje x categoría en módulo administración',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_marcaje_administracion_des')) {
            this.cantidadMarcajeAdminitracionDes = localStorage.getItem('cantidad_marcaje_administracion_des');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de marcaje x desafío en módulo administración',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_marcaje_administracion_giro')) {
            this.cantidadMarcajeAdminitracionGiro = localStorage.getItem('cantidad_marcaje_administracion_giro');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de marcaje por giro en módulo administración',
                'error'
            );
        }

        if (localStorage.getItem('plataforma')) {
            this.plataforma = localStorage.getItem('plataforma');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la plataforma',
                'error'
            );
        }

        this.cargarMarcarEnRuta();

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null) {
                this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);

            }
        });

        if (this.tipoConexion === ETipoConexion.BIKERACECLOUD) {
            this.resultadosSubscription = this.socketService.getResultadosObservable()
                .subscribe((resultados) => {
                    this.registrosSocket = resultados;
                });
        }
    }


    getMarcajes() {
        const source = interval(1000);
        this.subscription = source.subscribe(async val => {
            this.porcentaje = (val % 5) * 20;

            if (val % 5 === 0) {

                if (this.tipoConexion === ETipoConexion.BIKERACECLOUD && this.registrosSocket !== null) {
                    this.procesarResultados(this.registrosSocket['contenidoResultados']);
                }

                if (this.tipoConexion === ETipoConexion.RJ45) {
                    const filesResultados = [];
                    const resultados = await lastValueFrom(this.readFileService.getDirectory(localStorage.getItem('path')));
                    const respuestaResultados = resultados.match(/\b\w*resultados\w*\b/g);
                    respuestaResultados.forEach((file) => {
                        filesResultados.push(Number((file
                            .replace(/"/gi, '')
                            .replace(/lOG_resultados/gi, '')
                            .split('.txt')[0]))
                        );
                    });
                    this.prefixPathResultados = String(Math.max(...filesResultados));
                    this.fin = true;
                    this.getResultados();

                }

            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.resultadosSubscription) {
            this.resultadosSubscription.unsubscribe();
        }
    }

    async getResultados() {
        const resultados = await lastValueFrom(this.readFileService.getFile(`${localStorage.getItem('path')}/lOG_resultados${this.prefixPathResultados}.txt`));
        this.procesarResultados(resultados)
    }

    procesarResultados(resultados: string) {
        if (resultados === undefined) { return; }

        let marcajesCopy = [];
        let marcajesOrdenados = [];
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        this.lugarCategorias = {};
        resultados.toString().split('\n').forEach((linea, index) => {

            if (index > 0 && linea.length > 1) {
                if (linea.split('\t')[0] !== '') {

                    const giros = linea.split('\t').slice(5, 20);
                    const girosCompletados = giros.filter((giro) => giro !== '').length;

                    marcajesCopy.push({
                        dor: linea.split('\t')[0],
                        nombre: linea.split('\t')[3],
                        apellido: linea.split('\t')[2],
                        tiempo: linea.split('\t')[22],
                        categoria: linea.split('\t')[4].split(' ').join('_'),
                        giros: linea.split('\t')[33],
                        girosCompletados,
                        estadoTiempo: `${linea.split('\t')[23]}`,
                        team: `${linea.split('\t')[25]}`,
                        desafio: `${linea.split('\t')[41]}`,
                        sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                        tag: linea.split('\t')[1],
                        tiempos: giros,
                        id: `${linea.split('\t')[24]}`,

                    });
                }
            }
        });


        marcajesCopy.forEach((marcajeCopy) => {
            if (!this.lugarCategorias[marcajeCopy.categoria]) {
                this.lugarCategorias[marcajeCopy.categoria] = [];
            }

            this.lugarCategorias[marcajeCopy.categoria] = this.lugarCategorias[marcajeCopy.categoria].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                team: `${marcajeCopy.team}`,
                desafio: `${marcajeCopy.desafio}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);


            if (!lugarGiros[marcajeCopy.giros]) {
                lugarGiros[marcajeCopy.giros] = [];
            }

            lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                desafio: `${marcajeCopy.desafio}`,
                team: `${marcajeCopy.team}`,
                sexo: marcajeCopy.sexo.toLowerCase(),

            }]);

            if (!lugarDesafio[marcajeCopy.desafio]) {
                lugarDesafio[marcajeCopy.desafio] = [];
            }

            lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                team: `${marcajeCopy.team}`,
                desafio: `${marcajeCopy.desafio}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);
        });


        this.marcajes = marcajesCopy;

        marcajesOrdenados = this.marcajes.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);

        (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
            lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        this.marcajes.forEach((marcajeFor) => {
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro = index + 1;
                }

            });

            // MASCULINO
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro_sexo = index + 1;
                }
            });

            // FEMENINO
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro_sexo = index + 1;
                }
            });

            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                        marcajeFor.posicion_giro = estadoTiempo.DNF;
                        marcajeFor.posicion_giro_sexo = estadoTiempo.DNF;
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                        marcajeFor.posicion_giro = estadoTiempo.DNS;
                        marcajeFor.posicion_giro_sexo = estadoTiempo.DNS;
                    }

                    if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                        marcajeFor.posicion_giro = 'EN RUTA';
                        marcajeFor.posicion_giro_sexo = 'EN RUTA';
                    }
                }

            });
        });

        this.lugarGirosGeneral = lugarGiros;


        (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
            lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        this.marcajes.forEach((marcajeFor) => {
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio = index + 1;
                }

            });

            // MASCULINO
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio_sexo = index + 1;
                }

            });

            // FEMENINO
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio_sexo = index + 1;
                }

            });

            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                        marcajeFor.posicion_desafio = estadoTiempo.DNF;
                        marcajeFor.posicion_desafio_sexo = estadoTiempo.DNF;
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                        marcajeFor.posicion_desafio = estadoTiempo.DNS;
                        marcajeFor.posicion_desafio_sexo = estadoTiempo.DNS;
                    }

                    if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                        marcajeFor.posicion_desafio = 'EN RUTA';
                        marcajeFor.posicion_desafio_sexo = 'EN RUTA';
                    }
                }

            });
        });

        this.lugarDesafioGeneral = lugarDesafio;

        (Object.keys(this.lugarCategorias) as (keyof typeof this.lugarCategorias)[]).forEach((key, index) => {
            this.lugarCategorias[key] = this.lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        switch (this.tipoDisciplina) {
            case 'Trail':
                this.marcajes.forEach((marcajeFor) => {

                    if (this.tipoClasificacion === 'Por Giro' &&

                        (marcajeFor.posicion_giro_sexo > 0 &&
                            marcajeFor.posicion_giro_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                        marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                    }
                    if (this.tipoClasificacion === 'Por Desafio' && (marcajeFor.posicion_desafio_sexo > 0 && marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                        marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                    }


                });

                this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                    !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                ).forEach((marcajeFor) => {
                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }

                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'EN RUTA';
                            }

                        }

                    });
                });
                break;

            case 'Ciclismo':
                this.marcajes.forEach((marcajeFor) => {
                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }

                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'EN RUTA';
                            }

                        }

                    });
                });
                break;
            case 'Motociclismo':
                this.marcajes.forEach((marcajeFor) => {
                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }

                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'EN RUTA';
                            }

                        }

                    });
                });
                break;
        }

        this.lugarCategoriasGeneral = this.lugarCategorias;

        this.updatePagination();
    }


    buscarDesafioPremiacion(desafio) {
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }

    cambioPestana(indice) {
        this.pestanaSeleccionada = indice;
    }

    cargarMarcarEnRuta() {
        if (localStorage.getItem('marcaje_adm_mostrar_en_ruta')) {
            this.cantidadDeportistasEnRuta = Number(localStorage.getItem('marcaje_adm_mostrar_en_ruta'));
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad de deportistas a mostrar en ruta en módulo administración',
                'error'
            );
        }
    }

    formatearNombre(nombre: string, replaceFirst: boolean) {
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            partes.shift();
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }

    updatePagination() {
        const totalPages = Math.ceil(this.marcajes.length / this.pageSize);
        this.pages = Array(totalPages).fill(0);

        const start = this.currentPage * this.pageSize;
        const end = start + this.pageSize;
        this.paginatedData = this.marcajes.slice(start, end);
    }

    changePage(page: number) {
        if (page >= 0 && page < this.pages.length) {
            this.currentPage = page;
            this.updatePagination();
        }
    }

    publicar(tipoInscripcionId: number) {
        switch (tipoInscripcionId) {
            case 0:
                this.verificarDeportistas();
                break;
            case 1:
                this.confirmarExterno();
                break;
        }
    }

    confirmar() {
        Swal.fire({
            title: 'Verificación realizada con éxito',
            html: '¿Estás seguro que quieres publicar estos resultados?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar publicación',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.saveResultados();

            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Publicación cancelada!',
                    'No has publicado ningún resultado',
                    'error'
                );
            }
        });
    }

    confirmarExterno() {
        Swal.fire({
            title: 'Importación deportistas externos',
            html: '¿Estás seguro que quieres publicar estos resultados?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar publicación',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.saveResultadosExternos();

            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Publicación cancelada!',
                    'No has publicado ningún resultado',
                    'error'
                );
            }
        });
    }

    async saveResultados() {
        try {
            this.loading = true;
        if(this.plataforma === EPlataforma.BIKERACE){
            await lastValueFrom(this.resultadoService.importar({
                'evento': { 'id': this.eventoId },
                'data': this.marcajes,
                'tipoClasificacion': this.tipoClasificacion
            }));
        }

        if(this.plataforma === EPlataforma.RIDERTICKET){
            await lastValueFrom(this.resultadoRiderService.importar({
                'evento': { 'id': this.eventoId },
                'data': this.marcajes,
                'tipoClasificacion': this.tipoClasificacion
            }));

        }
        this.loading = false;
        Swal.fire(
            'Publicado con éxito',
            'Se han guardado los resultados exitosamente',
            'success'
        );
        } catch (error) {
            this.loading = false;
            Swal.fire(
                'Error',
                'Ocurrió un error intentelo más tarde',
                'error'
            );  
        }

    }

    async saveResultadosExternos() {
        try {
            this.loading = true;

            if(this.plataforma === EPlataforma.BIKERACE){
                await lastValueFrom(this.resultadoService.importarExterno({
                    'evento': { 'id': this.eventoId },
                    'data': this.marcajes,
                    'tipoClasificacion': this.tipoClasificacion
                }));
            }
    
            if(this.plataforma === EPlataforma.RIDERTICKET){
                await lastValueFrom(this.resultadoRiderService.importarExterno({
                    'evento': { 'id': this.eventoId },
                    'data': this.marcajes,
                    'tipoClasificacion': this.tipoClasificacion
                }));
    
            }
            this.loading = false;
            Swal.fire(
                'Publicado con éxito',
                'Se han guardado los resultados exitosamente',
                'success'
            );
        } catch (error) {
            this.loading = false;
            Swal.fire(
                'Error',
                'Ocurrió un error intentelo más tarde',
                'error'
            );
        }

    }

    async verificarDeportistas() {
        try {
            
            const dataSave = [];
        this.marcajes.forEach((corredor) => {
            dataSave.push({
                'categoriaId' : corredor.categoria.split('_')[0],
                'id' : corredor.id,
            });
        });

        this.loading = true;
        let verificacion = this.plataforma === EPlataforma.BIKERACE ? await lastValueFrom(this.corredorService.verificarCorredores(dataSave)) : await lastValueFrom(this.riderService.verificarRiders(dataSave));


        if (verificacion['message'] === 'Verificación realizada con éxito') {
            this.loading = false;
            this.confirmar();
        } else {
            this.loading = false;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: verificacion['message'],
            });
        }
        } catch (error) {
            this.loading = false;
            Swal.fire(
                'Error',
                'Ocurrió un error intentelo más tarde',
                'error'
            );   
        }


    }

}
