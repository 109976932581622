import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class EventoService {

  constructor(
    private http: HttpClient) { }

  obtenerEventosSimplify(estado: string) {
    return this.http.get<any>(`${environment.api}/evento/simplify/estado/${estado}`);
  }
}