import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AdminService {

  constructor(
    private http: HttpClient,
    private router: Router) { }

  login(formLogin: any) {
    return this.http.post<any>(`${environment.api}/admin/login`, formLogin);
  }

  logout() {
    localStorage.removeItem('dataUser');
    localStorage.removeItem('inicioOffline');
    this.router.navigate(['/login']);
  }

}
