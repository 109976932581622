<app-navbar-style-three></app-navbar-style-three>


<section class="checkout-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="checkout-item ">
                    <h2>Bienvenido, ingresa tus datos</h2>

                    <div class="checkout-one">
                        <form [formGroup]="formLogin">
                            <div class="form-group">
                                <label>Email:</label>
                                <input formControlName="email" type="email" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Contraseña:</label>
                                <input formControlName="password" type="password" class="form-control">
                            </div>


                        </form>
                    </div>
                </div>
            </div>


        </div>

        <div class="text-center">
            <button [disabled]="loading" (click)="login()" class="btn cmn-btn">Iniciar sesión</button>
            <button [disabled]="loading" (click)="iniciarOffline()" class="btn cmn-btn btn-secondary">Iniciar OFFLINE</button>
        </div>

    </div>
</section>

<app-preloader *ngIf="loading" ></app-preloader>
