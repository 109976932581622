import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {

  constructor(
    private route: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {

      const dataUser = localStorage.getItem('dataUser');
      const inicioOffline = localStorage.getItem('inicioOffline') === 'true';
      if (dataUser !== null || inicioOffline) {
        obs.next(true);
      } else {
        this.route.navigate(['/login']);
      }
    });
  }
}