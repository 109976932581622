import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';

@Component({
    selector: 'app-resultados-online',
    templateUrl: './resultados-online.component.html',
    styleUrls: ['./resultados-online.component.scss']
})
export class ResultadosOnlineComponent implements OnInit, OnDestroy {
    public dividirPantalla = null;
    public registrosSocket = {};


    private resultadosSubscription: Subscription;


    constructor(
        private socketService: SocketService,

    ) { }


    ngOnInit(): void {
        this.resultadosSubscription = this.socketService.getResultadosObservable()
            .subscribe((resultados) => {
                this.registrosSocket = resultados;
                if (this.registrosSocket !== null) {
                    this.dividirPantalla = this.registrosSocket['dividir_pantalla'];
                }
            });

    }

    ngOnDestroy() {
        if (this.resultadosSubscription) {
            this.resultadosSubscription.unsubscribe();
        }
    }

    marcajeEnRuta(marcajeEstadoTiempo: string) {
        // return marcajeEstadoTiempo !== estadoTiempo.DNS && marcajeEstadoTiempo !== estadoTiempo.DNF;
    }
}
