import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { BuscarInformacionComponent } from './components/pages/buscar-informacion/buscar-informacion.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import {
    ConfiguracionCronometrajeComponent
} from './components/pages/configuracion-cronometraje/configuracion-cronometraje.component';
import { EntregarComprobanteComponent } from './components/pages/entregar-comprobante/entregar-comprobante.component';
import { ErrorComponent } from './components/pages/error/error.component';
import {
    MarcajeAdminitracionComponent
} from './components/pages/marcaje-administracion/marcaje-administracion.component';
import { MarcajeComponent } from './components/pages/marcaje/marcaje.component';
import { PantallaCategoriaComponent } from './components/pages/pantalla-categoria/pantalla-categoria.component';
import { PantallaDesafioComponent } from './components/pages/pantalla-desafio/pantalla-desafio.component';
import { PantallaGiroComponent } from './components/pages/pantalla-giro/pantalla-giro.component';
import { SafePipe } from './pipes/safe.pipe';
import { ReadFileService } from './services/read-file.service';
import { LocalStorageService } from './shared/storage';
import { EncryptService } from './shared/encrypt';
import { NavbarLeftComponent } from './components/common/navbar-left/navbar-left.component';
import { TxtService } from './shared/txt_service';

const config: SocketIoConfig = { url: `${environment.api}`, options: { transports: ['websocket'] } };

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarStyleOneComponent,
        FooterStyleOneComponent,
        FooterStyleTwoComponent,
        NavbarStyleTwoComponent,
        ComingSoonComponent,
        ErrorComponent,
        NavbarStyleThreeComponent,
        SafePipe,
        ConfiguracionCronometrajeComponent,
        MarcajeComponent,
        BuscarInformacionComponent,
        EntregarComprobanteComponent,
        MarcajeAdminitracionComponent,
        PantallaDesafioComponent,
        PantallaGiroComponent,
        PantallaCategoriaComponent,
        NavbarLeftComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxQRCodeModule,
        SocketIoModule.forRoot(config),
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),

    ],
    providers: [
        DatePipe,
        ReadFileService,
        LocalStorageService,
        EncryptService,
        TxtService,
        { provide: 'WINDOW', useValue: window },
        { provide: LocationStrategy, useClass: HashLocationStrategy },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
