import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval, lastValueFrom } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { SocketService } from 'src/app/services/socket.service';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';

@Component({
    selector: 'app-envio-resultados',
    templateUrl: './envio-resultados.component.html',
    styleUrls: ['./envio-resultados.component.scss']
})
export class EnvioResultadosComponent implements OnInit, OnDestroy {

    public tipoClasificacion = '';
    public cantidadPorTabla = 0;
    public tipoDisciplina = '';
    subscription: Subscription;
    public segundos = 0;
    public porcentaje = 0;
    public marcajes: any[] = [];
    public registros: any[] = [];
    public desafiosPremiacion = [];
    public prefixPathResultados = '';
    public prefixPathRegistros = '';

    constructor(
        private readFileService: ReadFileService,
        private router: Router,
        private socketService: SocketService,
        private adminService: AdminService
    ) { }

    ngOnInit(): void {
        const isOffline = localStorage.getItem('inicioOffline');
        if (isOffline === 'true') {
            this.router.navigate(['/configuracion-cronometraje']);
            return;
        }

        if (localStorage.getItem('path')) {
            this.getMarcajes();
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }
    }

    getMarcajes() {
        const source = interval(1000);
        this.subscription = source.subscribe(async val => {
            this.segundos = val;
            this.porcentaje = (val % 2) * 50;
            if (val % 2 === 0) {
                const filesResultados = [];
                const filesRegistros = [];
                const resultados = await lastValueFrom(this.readFileService.getDirectory(localStorage.getItem('path')));
                const respuestaResultados = resultados.match(/\b\w*resultados\w*\b/g);
                respuestaResultados.forEach((file) => {
                    filesResultados.push(Number((file
                        .replace(/"/gi, '')
                        .replace(/lOG_resultados/gi, '')
                        .split('.txt')[0]))
                    );
                });
                this.prefixPathResultados = String(Math.max(...filesResultados));

                const respuestaRegistros = resultados.match(/\b\w*registros\w*\b/g);
                respuestaRegistros.forEach((file) => {
                    filesRegistros.push(Number((file
                        .replace(/"/gi, '')
                        .replace(/lOG_registros/gi, '')
                        .split('.txt')[0]))
                    );
                });

                this.prefixPathRegistros = String(Math.max(...filesRegistros));

                const contenidoResultados = await this.getResultados(localStorage.getItem('path'));
                const contenidoRegistros = await this.getRegistro(localStorage.getItem('path'));
                this.socketService.emitResultados({
                    'contenidoResultados': contenidoResultados,
                    'contenidoRegistros': contenidoRegistros,
                    'tipo_clasificacion': localStorage.getItem('tipo_clasificacion'),
                    'tipo_disciplina': localStorage.getItem('tipo_disciplina'),
                    'dividir_pantalla': localStorage.getItem('dividir_pantalla'),
                    'cantidad_tabla': localStorage.getItem('cantidad_tabla'),
                    'cantidad_marcaje_administracion_cat': localStorage.getItem('cantidad_marcaje_administracion_cat'),
                    'cantidad_marcaje_administracion_des': localStorage.getItem('cantidad_marcaje_administracion_des'),
                    'cantidad_marcaje_administracion_giro': localStorage.getItem('cantidad_marcaje_administracion_giro'),
                    'orden_pantalla': localStorage.getItem('orden_pantalla'),
                    'cambiar_cat': localStorage.getItem('cambiar_cat'),
                    'marcaje_adm_mostrar_en_ruta': localStorage.getItem('marcaje_adm_mostrar_en_ruta'),
                    'tipoConexion': localStorage.getItem('tipoConexion'),
                    'desafios_premiacion': localStorage.getItem('desafios_premiacion'),
                    'desafios': localStorage.getItem('desafios'),

                });

            }
        });

    }


    async getRegistro(path: string) {
        const registros: string = await lastValueFrom(this.readFileService.getFile(`${path}/lOG_registros${this.prefixPathRegistros}.txt`));
        return registros;
    }

    async getResultados(path: string) {
        const resultados = await lastValueFrom(this.readFileService.getFile(`${path}/lOG_resultados${this.prefixPathResultados}.txt`));
        return resultados;
    }

    logout() {
        this.adminService.logout();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
