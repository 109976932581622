<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <app-navbar-left></app-navbar-left>

            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav class="nav nav-pills nav-justified" style="margin-top: 15px;">
                                    <a class="nav-link pointer" (click)="cambioPestana(0)"
                                        [ngClass]="pestanaSeleccionada === 0 ? 'active' : ''">Importar inscripciones</a>
                                    <a class="nav-link pointer" (click)="cambioPestana(1)"
                                        [ngClass]="pestanaSeleccionada === 1 ? 'active' : ''">Importar inscripciones
                                        externas</a>
                                </nav>

                                <div class="checkout-item ">

                                    <div class="checkout-one" style="max-width: none; padding: 15px;">


                                        <ng-container *ngIf="pestanaSeleccionada === 0">
                                            <h3 style="text-align: center;">Inscripciones internas
                                                <button class="btn publicar btn-primary" (click)="publicar(0)"> <i class='bx bxs-cloud-upload'></i>&nbsp;Publicar</button>
                                            </h3>
                                            <br>
                                            <div class="table-responsive">
                                                <table class="table  table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Pos. Cat</th>
                                                            <th scope="col">Pos. Gen</th>
                                                            <th scope="col">Pos. Gen/Sexo</th>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Categoría</th>
                                                            <th scope="col">Desafío</th>
                                                            <th scope="col">Tiempo</th>
                                                            <th scope="col">Estado tiempo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let marcaje of paginatedData" [ngClass]="{
                                                            'green' : marcaje.estadoTiempo !== 'DNF' && marcaje.estadoTiempo !== 'DNS' && marcaje.girosCompletados == marcaje.giros, 'red': marcaje.estadoTiempo === 'DNF' || marcaje.estadoTiempo === 'DNS'}">
                                                            <td>
                                                                <span *ngIf="tipoDisciplina !== 'non-stop'">{{marcaje===
                                                                    null ? '-' : marcaje.posicion_categoria}} <span
                                                                        *ngIf="marcaje !== null && marcaje.posicion_categoria !== 'EN RUTA'">
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Giro' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">
                                                                    <span>{{marcaje===
                                                                        null ? '-' : marcaje.posicion_giro}}</span>

                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Desafio' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">
                                                                    <span>{{marcaje===
                                                                        null ? '-' : marcaje.posicion_desafio}}</span>

                                                                </ng-container>
                                                            </td>

                                                            <td>
                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Giro' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">

                                                                    <span>
                                                                        {{marcaje=== null ? '-' :
                                                                        marcaje.posicion_giro_sexo}}</span>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Desafio' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">

                                                                    <span>
                                                                        {{marcaje=== null ? '-' :
                                                                        marcaje.posicion_desafio_sexo}}</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>{{marcaje.nombre}} {{marcaje.apellido}}</td>
                                                            <td>{{formatearNombre(marcaje.categoria, true)}}</td>
                                                            <td>{{formatearNombre(marcaje.desafio, false)}}</td>
                                                            <td>{{marcaje.tiempo}}</td>
                                                            <td>{{marcaje.estadoTiempo === '' || marcaje.estadoTiempo === '0' ? 'NORMAL' : marcaje.estadoTiempo}}</td>

                                                        </tr>

                                                </table>
                                            </div>
                                            <nav>
                                                <ul class="pagination justify-content-center">
                                                  <li class="page-item" [class.disabled]="currentPage === 0">
                                                    <button class="page-link" (click)="changePage(currentPage - 1)">Anterior</button>
                                                  </li>
                                                  <li
                                                    class="page-item"
                                                    *ngFor="let page of pages; let pageIndex = index"
                                                    [class.active]="currentPage === pageIndex"
                                                  >
                                                    <button class="page-link" (click)="changePage(pageIndex)">{{ pageIndex + 1 }}</button>
                                                  </li>
                                                  <li class="page-item" [class.disabled]="currentPage === pages.length - 1">
                                                    <button class="page-link" (click)="changePage(currentPage + 1)">Siguiente</button>
                                                  </li>
                                                </ul>
                                              </nav>
                                        </ng-container>
                                        <ng-container *ngIf="pestanaSeleccionada === 1">
                                            <h3 style="text-align: center;">Inscripciones externas
                                                <button class="btn publicar btn-primary" (click)="publicar(1)"> <i class='bx bxs-cloud-upload'></i>&nbsp;Publicar</button>
                                            </h3>
                                            <br>
                                            <div class="table-responsive">
                                                <table class="table  table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Pos. Cat</th>
                                                            <th scope="col">Pos. Gen</th>
                                                            <th scope="col">Pos. Gen/Sexo</th>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Categoría</th>
                                                            <th scope="col">Desafío</th>
                                                            <th scope="col">Tiempo</th>
                                                            <th scope="col">Estado tiempo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let marcaje of paginatedData" [ngClass]="{
                                                            'green' : marcaje.estadoTiempo !== 'DNF' && marcaje.estadoTiempo !== 'DNS' && marcaje.girosCompletados == marcaje.giros, 'red': marcaje.estadoTiempo === 'DNF' || marcaje.estadoTiempo === 'DNS'}">
                                                            <td>
                                                                <span *ngIf="tipoDisciplina !== 'non-stop'">{{marcaje===
                                                                    null ? '-' : marcaje.posicion_categoria}} <span
                                                                        *ngIf="marcaje !== null && marcaje.posicion_categoria !== 'EN RUTA'">
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Giro' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">
                                                                    <span>{{marcaje===
                                                                        null ? '-' : marcaje.posicion_giro}}</span>

                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Desafio' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">
                                                                    <span>{{marcaje===
                                                                        null ? '-' : marcaje.posicion_desafio}}</span>

                                                                </ng-container>
                                                            </td>

                                                            <td>
                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Giro' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">

                                                                    <span>
                                                                        {{marcaje=== null ? '-' :
                                                                        marcaje.posicion_giro_sexo}}</span>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="tipoClasificacion==='Por Desafio' && tipoDisciplina !== 'non-stop' && tipoDisciplina !== 'Motociclismo'">

                                                                    <span>
                                                                        {{marcaje=== null ? '-' :
                                                                        marcaje.posicion_desafio_sexo}}</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>{{marcaje.nombre}} {{marcaje.apellido}}</td>
                                                            <td>{{formatearNombre(marcaje.categoria, true)}}</td>
                                                            <td>{{formatearNombre(marcaje.desafio, false)}}</td>
                                                            <td>{{marcaje.tiempo}}</td>
                                                            <td>{{marcaje.estadoTiempo === '' || marcaje.estadoTiempo === '0' ? 'NORMAL' : marcaje.estadoTiempo}}</td>

                                                        </tr>

                                                </table>
                                            </div>
                                            <nav>
                                                <ul class="pagination justify-content-center">
                                                  <li class="page-item" [class.disabled]="currentPage === 0">
                                                    <button class="page-link" (click)="changePage(currentPage - 1)">Anterior</button>
                                                  </li>
                                                  <li
                                                    class="page-item"
                                                    *ngFor="let page of pages; let pageIndex = index"
                                                    [class.active]="currentPage === pageIndex"
                                                  >
                                                    <button class="page-link" (click)="changePage(pageIndex)">{{ pageIndex + 1 }}</button>
                                                  </li>
                                                  <li class="page-item" [class.disabled]="currentPage === pages.length - 1">
                                                    <button class="page-link" (click)="changePage(currentPage + 1)">Siguiente</button>
                                                  </li>
                                                </ul>
                                              </nav>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-preloader *ngIf="loading"></app-preloader>