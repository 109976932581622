import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { estadoTiempo } from 'src/app/shared/const/estado-tiempo.enum';
import { sexo } from 'src/app/shared/const/sexo.enum';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';
declare var bootstrap: any;

@Component({
    selector: 'app-buscar-informacion',
    templateUrl: './buscar-informacion.component.html',
    styleUrls: ['./buscar-informacion.component.scss']
})
export class BuscarInformacionComponent implements OnInit, AfterViewInit {
    public marcajes: any[] = [];
    private compDestroy: Subject<boolean> = new Subject();
    public search: number;
    public marcaje = null;
    public date = new Date();
    @ViewChild('searchInput', { static: false }) yourInput: ElementRef;
    public print = false;
    public tipoClasificacion = '';
    public tipoDisciplina = '';
    public lugarCategoriasGeneral: any = {};
    public lugarGirosGeneral: any = {};
    public lugarDesafioGeneral: any = {};
    public lugarCategorias: any = {};
    public desafiosPremiacion = [];
    public loading = false;
    @ViewChild('filterInput') filterInput!: ElementRef;
    selectedItemIndex: number = -1;
    selectedItem: any = null;
    isModalOpen: boolean = false;
    filterTerm: string = '';
    private resultadosSubscription: Subscription;
    public prefixPathResultados = '';

    constructor(
        private readFileService: ReadFileService,
        private localStorageAs: LocalStorageService,
        private _router: Router,
    ) { }

    ngOnInit(): void {
        if (!localStorage.getItem('path')) {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }


        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null) {
                this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);

            }
        });


    }

    ngOnDestroy() {
        if (this.resultadosSubscription) {
            this.resultadosSubscription.unsubscribe();
        }
    }

    buscar() {
        this.loading = true;


        this.readFile();

    }


    async readFile() {
        const filesResultados = [];
        const resultados = await lastValueFrom(this.readFileService.getDirectory(localStorage.getItem('path')));
        const respuestaResultados = resultados.match(/\b\w*resultados\w*\b/g);
        respuestaResultados.forEach((file) => {
            filesResultados.push(Number((file
                .replace(/"/gi, '')
                .replace(/lOG_resultados/gi, '')
                .split('.txt')[0]))
            );
        });
        this.prefixPathResultados = String(Math.max(...filesResultados));
        this.getFile(`${localStorage.getItem('path')}/lOG_resultados${this.prefixPathResultados}.txt`);
    }

    getFile(path: string) {
        const petition = this.readFileService.getFile(`${path}`);
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.procesarBusqueda(res);
                },
                err => {
                    console.log(err);
                    this.loading = false;

                });
    }

    procesarBusqueda(res: string) {
        let marcajesCopy = [];
        let marcajesOrdenados = [];
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        this.lugarCategorias = {};

        res.toString().split('\n').forEach((linea, index) => {

            if (index > 0 && linea.length > 1) {
                if (linea.split('\t')[0] !== '') {

                    const giros = linea.split('\t').slice(5, 20);
                    const girosCompletados = giros.filter((giro) => giro !== '').length;
                    marcajesCopy.push({
                        dor: linea.split('\t')[0],
                        nombre: linea.split('\t')[3],
                        apellido: linea.split('\t')[2],
                        tiempo: linea.split('\t')[22],
                        categoria: linea.split('\t')[4].split(' ').join('_'),
                        giros: linea.split('\t')[33],
                        girosCompletados,
                        estadoTiempo: `${linea.split('\t')[23]}`,
                        team: `${linea.split('\t')[25]}`,
                        desafio: `${linea.split('\t')[40]}`,
                        sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                        tag: linea.split('\t')[1],
                        giroMenorHora: this.encontrarHoraMenor(linea.split('\t').slice(5, 20)),
                        giroMenorNumero: this.encontrarGiroMenor(linea.split('\t').slice(5, 20))
                    });
                }


            }
        });


        marcajesCopy.forEach((marcajeCopy) => {
            if (!this.lugarCategorias[marcajeCopy.categoria]) {
                this.lugarCategorias[marcajeCopy.categoria] = [];
            }

            this.lugarCategorias[marcajeCopy.categoria] = this.lugarCategorias[marcajeCopy.categoria].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                team: `${marcajeCopy.team}`,
                desafio: `${marcajeCopy.desafio}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);


            if (!lugarGiros[marcajeCopy.giros]) {
                lugarGiros[marcajeCopy.giros] = [];
            }

            lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                desafio: `${marcajeCopy.desafio}`,
                team: `${marcajeCopy.team}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);

            if (!lugarDesafio[marcajeCopy.desafio]) {
                lugarDesafio[marcajeCopy.desafio] = [];
            }

            lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                team: `${marcajeCopy.team}`,
                desafio: `${marcajeCopy.desafio}`,
                sexo: marcajeCopy.sexo.toLowerCase(),

            }]);
        });


        this.marcajes = marcajesCopy;

        marcajesOrdenados = this.marcajes.sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);

        (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
            lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        this.marcajes.forEach((marcajeFor) => {
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro = index + 1;
                }

            });

            // MASCULINO
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro_sexo = index + 1;
                }
            });

            // FEMENINO
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro_sexo = index + 1;
                }
            });

            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                        marcajeFor.posicion_giro = estadoTiempo.DNF;
                        marcajeFor.posicion_giro_sexo = estadoTiempo.DNF;
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                        marcajeFor.posicion_giro = estadoTiempo.DNS;
                        marcajeFor.posicion_giro_sexo = estadoTiempo.DNS;
                    }

                    if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                        marcajeFor.posicion_giro = 'GIROS INCOMPLETOS';
                        marcajeFor.posicion_giro_sexo = 'GIROS INCOMPLETOS';
                    }
                }

            });
        });

        this.lugarGirosGeneral = lugarGiros;


        (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
            lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });


        this.marcajes.forEach((marcajeFor) => {
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio = index + 1;
                }

            });

            // MASCULINO
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio_sexo = index + 1;
                }

            });

            // FEMENINO
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio_sexo = index + 1;
                }

            });

            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                        marcajeFor.posicion_desafio = estadoTiempo.DNF;
                        marcajeFor.posicion_desafio_sexo = estadoTiempo.DNF;
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                        marcajeFor.posicion_desafio = estadoTiempo.DNS;
                        marcajeFor.posicion_desafio_sexo = estadoTiempo.DNS;
                    }

                    if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                        marcajeFor.posicion_desafio = 'GIROS INCOMPLETOS';
                        marcajeFor.posicion_desafio_sexo = 'GIROS INCOMPLETOS';
                    }
                }

            });
        });

        this.lugarDesafioGeneral = lugarDesafio;


        (Object.keys(this.lugarCategorias) as (keyof typeof this.lugarCategorias)[]).forEach((key, index) => {
            this.lugarCategorias[key] = this.lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        switch (this.tipoDisciplina) {
            case 'Trail':
                this.marcajes.forEach((marcajeFor) => {
                    if (this.tipoClasificacion === 'Por Giro' &&

                        (marcajeFor.posicion_giro_sexo > 0 &&
                            marcajeFor.posicion_giro_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                        marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                    }
                    if (this.tipoClasificacion === 'Por Desafio' && (marcajeFor.posicion_desafio_sexo > 0 && marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                        marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                    }


                });

                this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                    !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                ).forEach((marcajeFor) => {

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => !this.estaPremiadoGeneral(filtro.dor) && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }
                    });

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }

                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                            }

                        }

                    });
                });
                break;
            case 'Ciclismo':
                this.marcajes.forEach((marcajeFor) => {
                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }

                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                            }

                        }

                    });
                });
                break;
            case 'Motociclismo':
                this.marcajes.forEach((marcajeFor) => {
                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor && marcajeCategoria.categoria === marcajeFor.categoria) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    this.lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor && marcajeCategoria.categoria === marcajeFor.categoria) {
                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }

                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'GIROS INCOMPLETOS';
                            }

                        }

                    });
                });
                break;
        }


        this.lugarCategoriasGeneral = this.lugarCategorias;


        if (this.search === null || this.search === undefined) { return; }

        const busquedaMarcaje = this.marcajes.filter((marcaje) => Number(marcaje.dor) === Number(this.search));

        if (busquedaMarcaje.length === 0) {
            this.marcaje = null;
        } else {
            this.marcaje = busquedaMarcaje[0];
            this.limpiar();
        }


        this.loading = false;
    }

    buscarDesafioPremiacion(desafio) {
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }

    encontrarHoraMenor(horas): string {
        let horaMenor: string | null = null;

        for (const hora of horas) {

            if (hora.length > 0 && (!horaMenor || hora < horaMenor)) {
                horaMenor = hora;
            }
        }

        return horaMenor || '';
    }

    cantidadPorCategoria(categoria) {
        if (this.lugarCategorias[categoria] === undefined) return 0;
        return this.lugarCategorias[categoria].length;
    }

    encontrarGiroMenor(horas): number {
        let giroMenor = 16;
        let horaMenor: string | null = null;
        let tieneGiro = false;

        let indice = 1;
        for (const hora of horas) {
            if (hora.length > 0 && (!horaMenor || hora < horaMenor)) {
                horaMenor = hora;
                giroMenor = indice;
                tieneGiro = true;
            }
            indice++;
        }

        return tieneGiro ? giroMenor : 0;
    }

    ngAfterViewInit() {
        this.yourInput.nativeElement.focus();
    }

    imprimir() {
        this.printElem('invoice-POS');
    }

    printElem(elem) {
        const mywindow = window.open('', 'PRINT',);

        mywindow.document.write('<html><head>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close();
        mywindow.focus();

        mywindow.print();
        mywindow.close();
        return true;
    }

    limpiar() {
        this.search = null;
        this.yourInput.nativeElement.focus();

    }

    nombreEvento() {
        return localStorage.getItem('nombre_evento') ? localStorage.getItem('nombre_evento') : '';
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (this.isModalOpen) {
            this.filterInput.nativeElement.focus();
            if (event.key === 'ArrowDown') {
                this.moveSelection(1);
            } else if (event.key === 'ArrowUp') {
                this.moveSelection(-1);
            } else if (event.key === 'Enter' && this.selectedItemIndex >= 0) {
                this.selectItem(this.filteredData()[this.selectedItemIndex]);
            }
        }

        if (!this.isModalOpen) {
            if (!this.isValidKey(event)) {
                event.preventDefault();
            }

            this.yourInput.nativeElement.focus();

            if (event.key === 'Enter') {
                this.buscar();
            }

            if (event.key === 'i' || event.key === 'I') {
                if (this.marcaje === null) return;
                this.search = null;
                this.imprimir();
            }

            if (event.key === 'b' || event.key === 'B') {
                this.marcaje = null;
                this.search = null;
                this.buscar();
                const modalElement = document.getElementById('exampleModal') as HTMLElement;
                const modal = new bootstrap.Modal(modalElement);
                modal.show();
                this.isModalOpen = true;

                modalElement.addEventListener('shown.bs.modal', () => {
                    this.filterInput.nativeElement.focus();
                });

                modalElement.addEventListener('hidden.bs.modal', () => {
                    this.yourInput.nativeElement.focus();
                    this.isModalOpen = false;

                    if (this.selectedItem !== null && this.selectedItem !== undefined) {
                        this.search = this.selectedItem.dor;
                        this.buscar();
                    }
                });

                this.selectedItemIndex = -1;
                this.filterTerm = '';
            }
        }
    }

    private isValidKey(event: KeyboardEvent): boolean {
        const validKeys = ['i', 'b', 'I', 'B', 'Enter', 'Backspace'];
        if ((event.key >= '0' && event.key <= '9') || validKeys.includes(event.key)) {
            return true;
        }

        return false;
    }

    closeModal(): void {
        const modalElement = document.getElementById('exampleModal') as HTMLElement;
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();

        this.isModalOpen = false;
        this.filterInput.nativeElement.focus();
    }

    moveSelection(direction: number): void {
        const filteredItems = this.filteredData();
        let newIndex = this.selectedItemIndex + direction;
        if (newIndex < 0) {
            newIndex = filteredItems.length - 1;
        } else if (newIndex >= filteredItems.length) {
            newIndex = 0;
        }
        this.selectedItemIndex = newIndex;
    }

    selectItem(item: any): void {
        this.selectedItem = item;
        this.closeModal();
    }

    hoverItem(index: number): void {
        this.selectedItemIndex = index;
    }

    clicknumber(number) {
        const nuevoNumero = `${this.search === undefined || this.search === null ? '' : this.search}${number}`;
        this.search = Number(nuevoNumero);
        this.yourInput.nativeElement.focus();
    }

    formatearNombre(nombre: string, replaceFirst: boolean) {
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            partes.shift();
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }

    estaPremiadoGeneral(dor) {
        return this.marcajes.find((marcaje) => marcaje.dor === dor && marcaje.posicion_categoria === 'PREMIADO/A EN GENERAL') !== undefined;
    }

    obtenerCheck(cat) {
        return this.marcaje.categoria === cat;
    }

    clickCategoria(marcaje) {
        this.marcaje = marcaje;
    }

    marcajeEnRuta(marcajeEstadoTiempo: string) {
        return marcajeEstadoTiempo !== estadoTiempo.DNS && marcajeEstadoTiempo !== estadoTiempo.DNF;
    }

    filteredData() {
        return this.marcajes.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase().includes(this.filterTerm.toLowerCase())
            )
        );
    }
}