<div class="navbar-area fixed-top">

    <div class="main-nav main-nav-three">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logobikerace.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav" >

                        <li class="nav-item" >
                            <a routerLink="/marcaje" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Marcaje</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/buscar-informacion" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Buscar Información</a>
                        </li>

                    </ul>

                    <div class="side-nav user">
                        <div *ngIf="currentRoute === '/marcaje'   && dividirPantalla === 'Categoria'" style="display: inline-flex;
                        align-items: center;
                        margin-right: 10px;">Desafio: 
                            <select [(ngModel)]="desafioSelected" class="form-control" style="font-size: 0.6rem;
                                padding: 0.2rem 0.75rem;margin-left: 10px;" (change)="cambioDesafio()">
                            <option value="Todos">Todos</option>
                            <option *ngFor="let desafio of desafios" [value]="desafio">{{desafio}}</option>
                        </select></div>
                        <a  routerLink="/configuracion-cronometraje"><i class='bx bxs-cog'></i> Configuración</a>

                    </div>

                </div>
            </nav>
        </div>
    </div>
</div>
