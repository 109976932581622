import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-navbar-left',
  templateUrl: './navbar-left.component.html',
  styleUrls: ['./navbar-left.component.scss']
})
export class NavbarLeftComponent implements OnInit {
  public inicioOffline: string = 'true';

  constructor(private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    const isOffline = localStorage.getItem('inicioOffline');
    if (isOffline !== 'true') {
        this.inicioOffline = 'false';
    }
  }

  logout() {
    this.adminService.logout();
  }
}
