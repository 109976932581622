import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable()
export class TxtService {
  constructor() { }

  public readTxt(fileList: FileList): Observable<any> {
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileList[0]);
    return new Observable((observer) => {
      let content = [];
      reader.onload = () => {
        const decoder = new TextDecoder('ISO-8859-1');
        const decodedString = decoder.decode(reader.result as ArrayBuffer);
        decodedString.toString().split('\n').forEach((linea, index) => {
          if (index > 0 && linea.length > 1) {
            if (linea.split('\t')[0] !== '') {
              let lineaRead: Object;
              lineaRead = linea.split('\t');
              // lineaRead[22] =  lineaRead[22].split('.')[0];
              content.push(lineaRead);
            }
          }
        });
        observer.next(content);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }
}
