import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ReadFileService {

    constructor(private http: HttpClient) { }

    getFile(path: string) {
        return this.http.get(`${path}`, {
            responseType: 'arraybuffer',
        })
            .pipe(
                map((response: ArrayBuffer) => {
                    const decoder = new TextDecoder('ISO-8859-1');
                    return decoder.decode(response);
                })
            );
    }



    getDirectory(path: string) {
        return this.http.get(`${path}`, { responseType: 'text' });
    }



}
