import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, throwError } from 'rxjs';

@Injectable()
export class CorredorService {

    constructor(
        private http: HttpClient) { }

    verificarCorredores(form: any) {
        return this.http.post(`${environment.api}/corredor/verificar-corredores`, form).pipe(
            catchError(err => {
              return throwError(() => new Error(err.message));
            })
          );;
    }
}