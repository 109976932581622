import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/storage';


@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {

  public dataUser = null;
  public currentRoute: string;
  public desafios = [];
  public desafioSelected: string = 'Todos';
  public dividirPantalla = '';
  private resultadosSubscription: Subscription;

  constructor(
    private router: Router,
    private localStorageAs: LocalStorageService,

  ) {

  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;

    this.dataUser = JSON.parse(localStorage.getItem('dataUser'));

    if (localStorage.getItem('dividir_pantalla')) {
      this.dividirPantalla = localStorage.getItem('dividir_pantalla');
    }

    this.localStorageAs.watch('desafios').subscribe(desafiosStorage => {
      if (desafiosStorage !== null) {
        this.desafios = JSON.parse(desafiosStorage);
      }
    });




    this.localStorageAs.watch('desafio_selected').subscribe(desafioSelectedStorage => {
      if (desafioSelectedStorage !== null) {
        this.desafioSelected = desafioSelectedStorage;
      }
    });
  }


  ngOnDestroy() {
    if (this.resultadosSubscription) {
      this.resultadosSubscription.unsubscribe();
    }
  }

  cambioDesafio() {
    this.localStorageAs.set('desafio_selected', this.desafioSelected);
    window.location.reload();
  }
}
