<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <app-navbar-left></app-navbar-left>

            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="row" style="margin-top:20px;margin-bottom: 20px">
                        <div class="progress">
                            <div class="progress-bar green" role="progressbar"
                                aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                                [style.width]="porcentaje + '%'">
                                {{porcentaje}}%
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;
                    width: 100%;
                    font-size: x-large;    margin-top: 11%;">
                        <span>Enviando información a Bikerace Cloud . . .</span>
                    </div>
                </section>



            </div>
        </div>
    </div>
</div>