import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval, lastValueFrom } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';
import { estadoTiempo } from 'src/app/shared/const/estado-tiempo.enum';
import { sexo } from 'src/app/shared/const/sexo.enum';
import { ETipoConexion } from 'src/app/shared/const/tipo-conexion.enum';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';
import { ReadFileService } from '../../../services/read-file.service';

@Component({
    selector: 'app-pantalla-categoria',
    templateUrl: './pantalla-categoria.component.html',
    styleUrls: ['./pantalla-categoria.component.scss']
})
export class PantallaCategoriaComponent implements OnInit, OnDestroy {

    public marcajes: any[] = [];
    public registros: any[] = [];

    subscription: Subscription;
    public porcentaje = 0;
    public tipoClasificacion = '';
    public tipoDisciplina = '';
    public marcajesUnicos: any[] = [];
    public cantidadPorTabla = 0;
    public ordenPantalla = null;
    public numerosDisponibles = [];
    public numerosRandom = [];
    public segundos = 0;
    public cambiarCat = '';
    public desafioSelected = 'Todos';
    public desafiosPremiacion = [];
    public prefixPathResultados = '';
    public tipoConexion = '';
    public inicioOffline = '';
    private resultadosSubscription: Subscription;
    public registrosSocket = {};
    public prefixPathRegistros = '';

    constructor(
        private readFileService: ReadFileService,
        private localStorageAs: LocalStorageService,
        private socketService: SocketService,
        private _router: Router,
    ) { }


    ngOnInit(): void {

        if (localStorage.getItem('tipoConexion')) {
            this.tipoConexion = localStorage.getItem('tipoConexion');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de conexión',
                'error'
            );
        }

        this.inicioOffline = localStorage.getItem('inicioOffline');

        if (this.tipoConexion === ETipoConexion.BIKERACECLOUD && this.inicioOffline === 'true') {
            Swal.fire(
                'Uppps....!',
                'Seleccionaste tipo de conexión Bikerace Cloud y iniciaste de forma OFFLINE',
                'error');
            this._router.navigate(['/configuracion-cronometraje']);
            return;
        }

        if (localStorage.getItem('path')) {
            this.getMarcajes();
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la ruta del archivo',
                'error'
            );
        }

        if (localStorage.getItem('tipo_clasificacion')) {
            this.tipoClasificacion = localStorage.getItem('tipo_clasificacion');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de clasificación',
                'error'
            );
        }

        if (localStorage.getItem('cantidad_tabla')) {
            this.cantidadPorTabla = Number(localStorage.getItem('cantidad_tabla'));
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar la cantidad por tabla',
                'error'
            );
        }

        if (localStorage.getItem('tipo_disciplina')) {
            this.tipoDisciplina = localStorage.getItem('tipo_disciplina');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el tipo de disciplina',
                'error'
            );
        }

        if (localStorage.getItem('orden_pantalla')) {
            this.ordenPantalla = localStorage.getItem('orden_pantalla');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el orden de deportistas',
                'error'
            );
        }

        if (localStorage.getItem('cambiar_cat')) {
            this.cambiarCat = localStorage.getItem('cambiar_cat');
        } else {
            Swal.fire(
                'Uppps....!',
                'Falta configurar el campo cambiar categoría',
                'error'
            );
        }

        this.localStorageAs.watch('desafio_selected').subscribe(desafioSelectedStorage => {
            if (desafioSelectedStorage !== null) {
                this.desafioSelected = desafioSelectedStorage;

            }
        });

        this.localStorageAs.watch('desafios_premiacion').subscribe(desafiosPremiacionStorage => {
            if (desafiosPremiacionStorage !== null) {
                this.desafiosPremiacion = JSON.parse(desafiosPremiacionStorage);

            }
        });

        if (this.tipoConexion === ETipoConexion.BIKERACECLOUD) {
            this.resultadosSubscription = this.socketService.getResultadosObservable()
                .subscribe((resultados) => {
                    this.registrosSocket = resultados;
                });
        }
    }


    getMarcajes() {
        const source = interval(1000);
        this.subscription = source.subscribe(async val => {
            this.segundos = val;
            this.porcentaje = (val % 2) * 50;
            if (val % 2 === 0) {
                if (this.tipoConexion === ETipoConexion.BIKERACECLOUD && this.registrosSocket !== null) {
                    this.procesarResultados(this.registrosSocket['contenidoResultados']);
                    this.procesarRegistros(this.registrosSocket['contenidoRegistros']);
                }

                if (this.tipoConexion === ETipoConexion.RJ45) {
                    const filesResultados = [];
                    const filesRegistros = [];
                    const resultados = await lastValueFrom(this.readFileService.getDirectory(localStorage.getItem('path')));
                    const respuestaResultados = resultados.match(/\b\w*resultados\w*\b/g);
                    respuestaResultados.forEach((file) => {
                        filesResultados.push(Number((file
                            .replace(/"/gi, '')
                            .replace(/lOG_resultados/gi, '')
                            .split('.txt')[0]))
                        );
                    });
                    this.prefixPathResultados = String(Math.max(...filesResultados));

                    const respuestaRegistros = resultados.match(/\b\w*registros\w*\b/g);
                    respuestaRegistros.forEach((file) => {
                        filesRegistros.push(Number((file
                            .replace(/"/gi, '')
                            .replace(/lOG_registros/gi, '')
                            .split('.txt')[0]))
                        );
                    });

                    this.prefixPathRegistros = String(Math.max(...filesRegistros));

                    this.getResultados();
                    this.getRegistros();
                }

            }
        });

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.resultadosSubscription) {
            this.resultadosSubscription.unsubscribe();
        }
    }

    compararPorHoraMayor(a, b) {
        const horaMayorA = a.horaMayor;
        const horaMayorB = b.horaMayor;

        if (horaMayorA < horaMayorB) {
            return 1; // Invierte el orden para mayor a menor
        }
        if (horaMayorA > horaMayorB) {
            return -1; // Invierte el orden para mayor a menor
        }
        return 0;
    }

    obtenerObjetoConHoraMayor() {
        let objetoConHoraMayor = null;

        for (const objeto of this.registros) {
            if (!objetoConHoraMayor || objeto.horaMayor > objetoConHoraMayor.horaMayor) {
                objetoConHoraMayor = objeto;
            }
        }

        return objetoConHoraMayor;
    }

    encontrarHoraMayor(horas): string {
        let horaMayor: string | null = null;

        for (const hora of horas) {
            if (!horaMayor || hora > horaMayor) {
                horaMayor = hora;
            }
        }

        return horaMayor || ''; // Devuelve la hora mayor o una cadena vacía si el array está vacío
    }

    async getResultados() {
        const resultados = await lastValueFrom(this.readFileService.getFile(`${localStorage.getItem('path')}/lOG_resultados${this.prefixPathResultados}.txt`));
        this.procesarResultados(resultados)
    }

    async getRegistros() {
        const resultados = await lastValueFrom(this.readFileService.getFile(`${localStorage.getItem('path')}/lOG_registros${this.prefixPathRegistros}.txt`));
        this.procesarRegistros(resultados)
    }

    async procesarResultados(resultados: string) {
        if (resultados === undefined) { return; }

        let marcajesCopy = [];
        const lugarCategorias: any = {};
        const lugarGiros: any = {};
        const lugarDesafio: any = {};
        resultados.toString().split('\n').forEach((linea, index) => {
            if (index > 0 && linea.length > 1) {
                if (linea.split('\t')[0] !== '') {

                    const giros = linea.split('\t').slice(5, 20);
                    const girosCompletados = giros.filter((giro) => giro !== '').length;

                    marcajesCopy.push({
                        dor: linea.split('\t')[0],
                        nombre: linea.split('\t')[3],
                        apellido: linea.split('\t')[2],
                        tiempo: linea.split('\t')[22],
                        categoria: linea.split('\t')[4].split(' ').join('_'),
                        giros: Number(linea.split('\t')[33]),
                        girosCompletados,
                        estadoTiempo: `${linea.split('\t')[23]}`,
                        desafio: `${linea.split('\t')[41]}`,
                        tag: linea.split('\t')[1],

                        sexo: (linea.split('\t')[39]).toString().toLowerCase(),
                    });



                }
            }
        });


        marcajesCopy.forEach((marcajeCopy) => {
            if (!lugarCategorias[marcajeCopy.categoria]) {
                lugarCategorias[marcajeCopy.categoria] = [];
            }

            lugarCategorias[marcajeCopy.categoria] = lugarCategorias[marcajeCopy.categoria].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                team: `${marcajeCopy.team}`,
                desafio: `${marcajeCopy.desafio}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);


            if (!lugarGiros[marcajeCopy.giros]) {
                lugarGiros[marcajeCopy.giros] = [];
            }

            lugarGiros[marcajeCopy.giros] = lugarGiros[marcajeCopy.giros].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                desafio: `${marcajeCopy.desafio}`,
                team: `${marcajeCopy.team}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);

            if (!lugarDesafio[marcajeCopy.desafio]) {
                lugarDesafio[marcajeCopy.desafio] = [];
            }

            lugarDesafio[marcajeCopy.desafio] = lugarDesafio[marcajeCopy.desafio].concat([{
                dor: marcajeCopy.dor,
                nombre: marcajeCopy.nombre,
                apellido: marcajeCopy.apellido,
                tiempo: marcajeCopy.tiempo,
                categoria: marcajeCopy.categoria,
                giros: marcajeCopy.giros,
                girosCompletados: marcajeCopy.girosCompletados,
                estadoTiempo: `${marcajeCopy.estadoTiempo}`,
                team: `${marcajeCopy.team}`,
                desafio: `${marcajeCopy.desafio}`,
                sexo: marcajeCopy.sexo.toLowerCase(),
            }]);
        });


        this.marcajes = marcajesCopy;
        // ORDEN CLASIFICACION POR GIRO

        (Object.keys(lugarGiros) as (keyof typeof lugarGiros)[]).forEach((key, index) => {
            lugarGiros[key] = lugarGiros[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        this.marcajes.forEach((marcajeFor) => {
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro = index + 1;
                }

            });

            //MASCULINO
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro_sexo = index + 1;
                }
            });

            //FEMENINO
            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_giro_sexo = index + 1;
                }
            });

            lugarGiros[marcajeFor.giros].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                        marcajeFor.posicion_giro = 'EN RUTA';
                        marcajeFor.posicion_giro_sexo = 'EN RUTA';
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                        marcajeFor.posicion_giro = estadoTiempo.DNF;
                        marcajeFor.posicion_giro_sexo = estadoTiempo.DNF;
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                        marcajeFor.posicion_giro = estadoTiempo.DNS;
                        marcajeFor.posicion_giro_sexo = estadoTiempo.DNS;
                    }


                }

            });
        });

        // ORDEN CLASIFICACION POR DESAFIO


        (Object.keys(lugarDesafio) as (keyof typeof lugarDesafio)[]).forEach((key, index) => {
            lugarDesafio[key] = lugarDesafio[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });

        this.marcajes.forEach((marcajeFor) => {
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio = index + 1;
                }

            });

            //MASCULINO
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.MASCULINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio_sexo = index + 1;
                }

            });

            //FEMENINO
            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.sexo === sexo.FEMENINO && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    marcajeFor.posicion_desafio_sexo = index + 1;
                }

            });

            lugarDesafio[marcajeFor.desafio].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                if (marcajeCategoria.dor === marcajeFor.dor) {
                    if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                        marcajeFor.posicion_desafio = 'EN RUTA';
                        marcajeFor.posicion_desafio_sexo = 'EN RUTA';
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                        marcajeFor.posicion_desafio = estadoTiempo.DNF;
                        marcajeFor.posicion_desafio_sexo = estadoTiempo.DNF;
                    }

                    if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                        marcajeFor.posicion_desafio = estadoTiempo.DNS;
                        marcajeFor.posicion_desafio_sexo = estadoTiempo.DNS;
                    }


                }

            });
        });

        //GUARDAR DESAFIOS EN LOCALSTORAGE
        localStorage.setItem('desafios', JSON.stringify(Object.keys(lugarDesafio)));


        (Object.keys(lugarCategorias) as (keyof typeof lugarCategorias)[]).forEach((key, index) => {
            lugarCategorias[key] = lugarCategorias[key].sort((a, b) => (a.tiempo < b.tiempo) ? -1 : 1);
        });
        switch (this.tipoDisciplina) {
            case 'Trail':
                this.marcajes.forEach((marcajeFor) => {

                    if (this.tipoClasificacion === 'Por Giro' &&

                        (marcajeFor.posicion_giro_sexo > 0 &&
                            marcajeFor.posicion_giro_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                        marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';
                    }
                    if (this.tipoClasificacion === 'Por Desafio' && (marcajeFor.posicion_desafio_sexo > 0 && marcajeFor.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFor.desafio))) {
                        marcajeFor.posicion_categoria = 'PREMIADO/A EN GENERAL';

                    }


                });

                this.marcajes.filter((marcajeFiltro) => !(this.tipoClasificacion === 'Por Giro' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio))) &&
                    !(this.tipoClasificacion === 'Por Desafio' && (marcajeFiltro.posicion_desafio_sexo > 0 && marcajeFiltro.posicion_desafio_sexo <= this.buscarDesafioPremiacion(marcajeFiltro.desafio)))
                ).forEach((marcajeFor) => {

                    lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => !this.estaPremiadoGeneral(filtro.dor) && filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'EN RUTA';
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }



                        }

                    });
                });
                break;

            case 'Ciclismo':
                this.marcajes.forEach((marcajeFor) => {
                    lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'EN RUTA';
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }



                        }

                    });
                });
                break;
            case 'Motociclismo':
                this.marcajes.forEach((marcajeFor) => {
                    lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo !== estadoTiempo.DNF && filtro.estadoTiempo !== estadoTiempo.DNS && filtro.giros == filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            marcajeFor.posicion_categoria = index + 1;
                        }

                    });

                    lugarCategorias[marcajeFor.categoria.split(' ').join('_')].filter((filtro) => filtro.estadoTiempo === estadoTiempo.DNF || filtro.estadoTiempo === estadoTiempo.DNS || filtro.giros !== filtro.girosCompletados).forEach((marcajeCategoria, index) => {
                        if (marcajeCategoria.dor === marcajeFor.dor) {
                            if (marcajeCategoria.girosCompletados < marcajeCategoria.giros) {
                                marcajeFor.posicion_categoria = 'EN RUTA';
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNF) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNF;
                            }

                            if (marcajeCategoria.estadoTiempo === estadoTiempo.DNS) {
                                marcajeFor.posicion_categoria = estadoTiempo.DNS;
                            }



                        }

                    });
                });
                break;
        }
        this.agruparPorCategoria();
    }

    async procesarRegistros(resultados: string) {
        if (resultados === undefined) { return; }
        const marcajesCopy = [];
        resultados.toString().split('\n').forEach((linea, index) => {
            if (index > 0 && linea.length > 1) {
                if (linea.split('\t')[0] !== '') {

                    if (this.buscarDor(linea.split('\t')[0]).estadoTiempo !== estadoTiempo.DNS) {
                        marcajesCopy.push({
                            tag: linea.split('\t')[0],
                            fechaPartida: linea.split('\t')[1],
                            horaMayor: this.encontrarHoraMayor(linea.split('\t').slice(2, 18)),
                            categoria: this.buscarDor(linea.split('\t')[0]).categoria,
                            desafio: this.buscarDor(linea.split('\t')[0]).desafio

                        });
                    }




                }
            }
        });

        this.registros = marcajesCopy;

        this.registros.sort(this.compararPorHoraMayor);
        const cantidadCategorias = this.primerasOcurrencias();

        switch (cantidadCategorias.length) {
            case 0:

                this.numerosRandom = [
                    undefined
                ];


                break;
            case 1:
                if (
                    this.numerosRandom[0] === undefined
                ) {
                    this.numerosRandom = [
                        this.obtenerNumeroRandom()
                    ];
                }

                break;
            case 2:
                if (
                    this.numerosRandom[0] === undefined &&
                    this.numerosRandom[1] === undefined
                ) {
                    this.numerosRandom = [
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom()
                    ];
                }
                break;
            case 3:
                if (
                    this.numerosRandom[0] === undefined &&
                    this.numerosRandom[1] === undefined &&
                    this.numerosRandom[2] === undefined
                ) {
                    this.numerosRandom = [
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom()
                    ];
                }

                break;
            case 4:
                if (
                    this.numerosRandom[0] === undefined &&
                    this.numerosRandom[1] === undefined &&
                    this.numerosRandom[2] === undefined &&
                    this.numerosRandom[3] === undefined
                ) {
                    this.numerosRandom = [
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom()
                    ];
                }

                break;
            default:
                if (
                    this.numerosRandom[0] === undefined &&
                    this.numerosRandom[1] === undefined &&
                    this.numerosRandom[2] === undefined &&
                    this.numerosRandom[3] === undefined
                ) {
                    this.numerosRandom = [
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom()
                    ];
                }
                if (this.segundos % 10 === 0) {
                    this.numerosRandom = [
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom(),
                        this.obtenerNumeroRandom()
                    ];
                }
                break;
        }
    }

    buscarDesafioPremiacion(desafio) {
        return this.desafiosPremiacion[desafio] === undefined ? 0 : this.desafiosPremiacion[desafio];
    }

    formatearNombre(nombre: string, replaceFirst: boolean) {


        if (nombre === undefined) { return ''; }
        let nombreReturn = nombre.split('_').join(' ');
        if (replaceFirst) {

            const partes = nombreReturn.split(" ");
            // Elimina la primera parte (el número)
            partes.shift();
            // Une las partes restantes
            nombreReturn = partes.join(" ");
            return nombreReturn;
        } else {
            return nombreReturn;
        }

    }

    agruparPorCategoria() {
        const objetosAgrupadosPorCategoria = {};
        this.marcajes.forEach((objeto) => {
            const categoria = objeto.categoria;
            if (objetosAgrupadosPorCategoria.hasOwnProperty(categoria)) {
                objetosAgrupadosPorCategoria[categoria].push(objeto);
            } else {
                objetosAgrupadosPorCategoria[categoria] = [objeto];
            }
        });
        return objetosAgrupadosPorCategoria;

    }

    arrayCantidadCategoria(key, utilizarConfOrden: boolean) {
        if (this.agruparPorCategoria()[key] === undefined) { return []; }

        if (utilizarConfOrden) {
            if (this.ordenPantalla === 'ASC') {
                return this.agruparPorCategoria()[key]
                    .filter((marcaje) => marcaje.estadoTiempo !== estadoTiempo.DNS)
                    .sort((a, b) => {
                        if (a.giros > a.girosCompletados && b.giros <= b.girosCompletados) {
                            return 1; // a va después de b si tiene más giros pendientes
                        }
                        if (a.giros <= a.girosCompletados && b.giros > b.girosCompletados) {
                            return -1; // a va antes de b si tiene menos giros pendientes
                        }
                        if (a.estadoTiempo === estadoTiempo.DNF && b.estadoTiempo !== estadoTiempo.DNF) {
                            return 1; // a va después de b si a es DNF
                        }
                        if (a.estadoTiempo !== estadoTiempo.DNF && b.estadoTiempo === estadoTiempo.DNF) {
                            return -1; // a va antes de b si b es DNF
                        }
                        return a.tiempo.localeCompare(b.tiempo); // orden normal
                    })
                    .slice(0, this.cantidadPorTabla);

            }

            if (this.ordenPantalla === 'DESC') {
                return this.agruparPorCategoria()[key].filter((marcaje) => marcaje.estadoTiempo !== estadoTiempo.DNS).sort((a, b) => b.tiempo.localeCompare(a.tiempo)).slice(0, this.cantidadPorTabla);

            }
        } else {
            return this.agruparPorCategoria()[key].filter((marcaje) => marcaje.estadoTiempo !== estadoTiempo.DNS).sort((a, b) => b.tiempo.localeCompare(a.tiempo)).slice(0, this.cantidadPorTabla);

        }

    }

    estaPremiadoGeneral(dor) {
        return this.marcajes.find((marcaje) => marcaje.dor === dor && marcaje.posicion_categoria === 'PREMIADO/A EN GENERAL') !== undefined;
    }

    iteraciones(key) {
        const array = this.arrayCantidadCategoria(key, false);
        return Array(this.cantidadPorTabla - array.length).fill(0);

    }

    split(array, limit) {
        return array.filter((registro) => registro.desafio === this.desafioSelected || this.desafioSelected === 'Todos').slice(0, limit);
    }

    buscarDor(tag) {
        const busqueda = this.marcajes.find((marcaje) => marcaje.tag === tag);
        if (busqueda === undefined) { return { estadoTiempo: estadoTiempo.DNS }; }
        return busqueda;
    }

    primerasOcurrencias() {
        let categoriasSeleccionadas = new Set();
        let primerasCuatroOcurrencias = [];
        for (let i = 0; i < this.registros.length; i++) {
            let objeto = this.registros[i];
            let categoria = objeto.categoria;
            let desafio = objeto.desafio;

            if (this.desafioSelected === 'Todos' || this.desafioSelected === desafio) {
                if (!categoriasSeleccionadas.has(categoria)) {
                    // Agregar la categoría al conjunto de categorías seleccionadas
                    categoriasSeleccionadas.add(categoria);

                    // Agregar el objeto al arreglo de primeras 4 ocurrencias únicas
                    primerasCuatroOcurrencias.push(objeto);
                }
            }
            // Verificar si la categoría ya ha sido seleccionada

        }
        return primerasCuatroOcurrencias;
    }

    inicializarNumeros() {
        for (let i = 0; i <= this.primerasOcurrencias().length - 1; i++) {
            this.numerosDisponibles.push(i);
        }
    }

    obtenerNumeroRandom() {
        if (this.numerosDisponibles.length === 0) {
            this.inicializarNumeros();
        }
        let indiceAleatorio = Math.floor(Math.random() * this.numerosDisponibles.length);
        let numero = this.numerosDisponibles.splice(indiceAleatorio, 1)[0];
        return numero;
    }

    marcajeEnRuta(marcajeEstadoTiempo: string) {
        return marcajeEstadoTiempo !== estadoTiempo.DNS && marcajeEstadoTiempo !== estadoTiempo.DNF;
    }
}
